import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import instance from "../../api/axios";

export default function ArtistFilter() {
  const [hideArtist, setHideArtist] = useState(false);
  const [artistInfo, setArtistInfo] = useState([]);
  const [query, setQuery] = useState("");

  const [hideSeeMore, setHideSeeMore] = useState(false);

  function getArtist() {
    instance
      .get("/work/market?lang=fr&page=0", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setArtistInfo(res.data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getArtist();
  }, []);

  return (
    <div>
      {hideArtist ? (
        <div className="mb-2">
          <button
            onClick={() => {
              setHideArtist(false);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">Artiste</p>

            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div>
          <div className="">
            <button
              onClick={() => {
                setHideArtist(true);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">Artiste</p>

              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px mb-6">
            <div>
              <input
                type="text"
                placeholder="Rechercher un artiste"
                className="input input-bordered input-sm w-4/5 rounded-md max-w-xs bg-white"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {artistInfo.map((item) => (
              <div className="my-2" key={item.work_id}>
                <label className="flex flex-row justify-between items-center cursor-pointer ">
                  <div className="flex flex-row items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 border-[#C7C7C7] checked:bg-purple checked"
                    />

                    <span className="label-text ml-2 text-[#444444]">
                      {item.artist_name}
                    </span>
                  </div>
                </label>
              </div>
            ))}

            <div className="flex flex-col items-center text-[#C4c4c4]">
              <button className="flex items-center mb-2">
                <p>Voir plus</p>
                <BiChevronDown />
              </button>
              <span className="h-px w-[80%] bg-[#C4c4c4]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
