import React from "react";
import { Link } from "react-router-dom";

export default function AdminFooter() {
  return (
    <footer className="footer footer-center py-3 px-96 bg-black text-white bottom-0">
      <p>Crée par Junior ISEP</p>
    </footer>
  );
}
