import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import { BiChevronLeft } from "react-icons/bi";
import { FiTrendingDown, FiTrendingUp } from "react-icons/fi";
import { TextField } from "@mui/material";
import Carousel from "./carousel";
import { useNavigate } from "react-router-dom";

import instance from "../api/axios";
import StockChart from "./chart";
import { BsDownload } from "react-icons/bs";

const LANG = localStorage.getItem("lng") || "fr";

export default function OeuvresDeco({ workId }) {
  const [workInfo, setWorkInfo] = useState([]);

  function getWorkInfoById() {
    instance
      .get(`/work?lang=${LANG}&work_id=${workId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setWorkInfo(res.data);
        console.log(workInfo);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    return getWorkInfoById();
  }, []);

  return (
    <div>
      <NavigationBar />
      <div className="xl:mx-36 lg:mx-10 mx-5 lg:my-10 my-8 bg-white">
        <Link
          to="/market"
          className="flex items-center font-serif text-black font-medium text-xl"
        >
          <BiChevronLeft />
          <p>Retour aux Marchés</p>
        </Link>
        <div className="flex justify-between my-4 font-serif2">
          <div>
            {workInfo.is_introductory ? (
              <p className="text-lg text-[#757575]">Marché secondaire</p>
            ) : (
              <p className="text-lg text-[#757575]">Marché primaire</p>
            )}
            <p className="text-black font-serif text-4xl font-medium ">
              {workInfo.title}
            </p>
            {workInfo.is_introductory ? (
              <p className="text-purple mt-2">Arction d'introduction</p>
            ) : (
              <p className="text-purple mt-2">Arction ordinaire</p>
            )}
          </div>
          <div className="flex flex-col items-end">
            <p className="text-[#757575] text-lg">
              Valeur totale des Artctions
            </p>
            <p className="text-black text-3xl">{workInfo.total_price} €</p>
            {workInfo.evolution >= 0 ? (
              <div className="flex items-center space-x-1 text-green text-lg">
                <FiTrendingUp />
                <p>{workInfo.evolution} %</p>
              </div>
            ) : (
              <div className="flex items-center space-x-1 text-red text-lg">
                <FiTrendingDown />
                <p>{workInfo.evolution} %</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex lg:flex-row flex-col justify-between mb-36">
          <div className="lg:w-3/5">
            <StockChart workId={workId} />
          </div>
          <div className="lg:w-[35%] xl:w-[30%]">
            <div className="border rounded p-8 pt-5 mb-5 font-serif2 text-black border-purple/40">
              <div className="mb-5">
                <p>Prix de l'Artction</p>
                <p className="text-3xl">{workInfo.avg_price} €</p>
              </div>
              <div>
                <p>Nombres d'Artctions Totales</p>
                <p className="text-3xl">{workInfo.nb_available}</p>
              </div>
            </div>
            <div className="flex flex-col border rounded p-8 pt-3 border-purple/40">
              <span className="mb-8 mt-3">
                <TextField
                  id="outlined-basic"
                  label="Nombres d'Artctions"
                  type="number"
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                />
              </span>
              <span>
                <TextField
                  id="outlined-basic"
                  label="Montant"
                  type="number"
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                />
              </span>

              <div className="flex items-center justify-center bg-gray-dark text-white font-serif2 normal-case font-normal text-lg py-2 rounded-md mt-8">
                <Link to="/signin" className="text-light-purple mr-2">
                  Se connecter
                </Link>{" "}
                ou
                <Link to="/signup" className="text-light-purple ml-2">
                  S'inscrire
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-gray-dark h-[720px] absolute w-full -z-10 mt-20"></div>
        <Carousel />
        <div className="xl:mx-72 lg:mx-36 mx-5 mb-40">
          <p className="font-serif font-medium text-4xl text-white">
            {workInfo.title}
          </p>
          <p className="font-serif2 text-lg mt-2 text-[#D9D9D9]">
            {workInfo.description}
          </p>
          <button className="btn mt-10 bg-purple border-none text-white font-serif2 normal-case font-medium text-lg px-5 space-x-2 md:w-96 w-full hover:bg-purple/80 hover:bg-none">
            <BsDownload size={18} />
            <p>Télécharger le documents de l'oeuvre</p>
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}
