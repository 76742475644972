import React, { useState, useEffect } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import axios from "../api/axios";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function OrderHistoryComp({ data }) {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [query, setQuery] = useState("");
  const [seeMore, setSeeMore] = useState(false);

  const { t } = useTranslation("wallet");

  const navigate = useNavigate();

  return (
    <div>
      {data.status === "passed" ? (
        <div className="rounded-lg py-3 px-5 flex flex-col bg-white gap-4 font-serif2 border border-light-purple my-2">
          <div className="flex justify-between">
            <div className="text-purple">
              <button
                className="font-bold text-lg"
                onClick={() => navigate("/oeuvre", { state: data.work_id })}
              >
                {data.work_title}
              </button>
            </div>
            <div className="text-right">
              {data.type === "sell" ? (
                <p className="text-red font-semibold text-lg">
                  {t("userSell")}
                </p>
              ) : (
                <p className="text-green font-semibold text-lg">
                  {t("userBuy")}
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-14 text-xs">
            <div>
              <p className=" text-even-lighter-gray/60">
                Nombre <br /> d'Artctions
              </p>
              <p className="text-purple text-lg font-medium">
                {data.nb_artctions}
              </p>
            </div>
            <div className="flex gap-5">
              <div className="h-full w-px bg-even-lighter-gray/20"></div>
              <div>
                <p className=" text-even-lighter-gray/60">
                  Prix de <br /> l'Artction
                </p>
                <p className="text-purple text-lg font-medium">233,3€</p>
              </div>
            </div>
            <div className="flex gap-5">
              <div className="h-full w-px bg-even-lighter-gray/20"></div>
              <div>
                <p className=" text-even-lighter-gray/60">
                  Montant <br /> Total
                </p>
                <p className="text-purple text-lg font-medium">
                  {data.total_amount} €
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-end">
            <div>
              <p className=" text-even-lighter-gray/60 text-xs">Date d'exp.</p>
              <p className="text-purple/70 text-sm font-medium -mt-1">
                {moment(data.expiration_date).format("DD/MM/YYYY")}
              </p>
            </div>
            <a href="/" className="text-red text-lg">
              Annuler l'ordre
            </a>
          </div>
        </div>
      ) : (
        <div className="rounded-lg py-3 px-5 flex flex-col bg-white gap-4 font-serif2 border border-light-purple my-2">
          <div className="flex justify-between opacity-60">
            <div className="text-purple">
              <button
                className="font-bold text-lg"
                onClick={() => navigate("/oeuvre", { state: data.work_id })}
              >
                {data.work_title}
              </button>
            </div>
            <div className="text-right">
              {data.type === "sell" ? (
                <p className="text-red font-semibold text-lg">
                  {t("userSell")}
                </p>
              ) : (
                <p className="text-green font-semibold text-lg">
                  {t("userBuy")}
                </p>
              )}
            </div>
          </div>
          <div className="flex gap-14 text-xs opacity-60">
            <div>
              <p className=" text-even-lighter-gray/60">
                Nombre <br /> d'Artctions
              </p>
              <p className="text-purple text-lg font-medium">
                {data.nb_artctions}
              </p>
            </div>
            <div className="flex gap-5">
              <div className="h-full w-px bg-even-lighter-gray/20"></div>
              <div>
                <p className=" text-even-lighter-gray/60">
                  Prix de <br /> l'Artction
                </p>
                <p className="text-purple text-lg font-medium">
                  {data.total_amount / data.nb_artctions} €
                </p>
              </div>
            </div>
            <div className="flex gap-5">
              <div className="h-full w-px bg-even-lighter-gray/20"></div>
              <div>
                <p className=" text-even-lighter-gray/60">
                  Montant <br /> Total
                </p>
                <p className="text-purple text-lg font-medium">
                  {data.total_amount} €
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-end opacity-60">
            <div>
              <p className=" text-even-lighter-gray/60 text-xs">Date d'exp.</p>
              <p className="text-purple/70 text-sm font-medium -mt-1">
                {moment(data.expiration_date).format("DD/MM/YYYY")}
              </p>
            </div>
            <a href="/" className="text-purple text-lg">
              Annulé
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
