import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import TitleProp from "../components/titleprop";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const { t } = useTranslation("contact");
  const navigate = useNavigate();

  return (
    <div className="bg-white">
      <NavigationBar />
      <TitleProp directory={t("contact")} />
      <div className="lg:mx-72 mx-16 mt-20 mb-60">
        <p className="font-serif2 text-purple text-xl">{t("contact")}</p>
        <div className="border-purple/40 rounded-sm w-fit border py-8 px-16 font-serif2 text-purple text-xl space-y-4">
          <p>Adresse email</p>
          <p>Tel</p>
        </div>
        <button
          className="btn bg-gray-dark text-white font-serif2 normal-case font-normal text-lg mt-6 px-20 rounded"
          onClick={() => {
            navigate("/help");
          }}
        >
          {t("help")}
        </button>
      </div>
      <Footer />
    </div>
  );
}
