import React from "react";
import { useNavigate } from "react-router-dom";

export default function AdminUsersList({ data }) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="border-purple/40 rounded border font-serif2 p-5 px-8 space-y-5">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-xs text-even-lighter-gray">Nom</p>
            <p className="text-lg text-purple">{data.lastName}</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Prénom</p>
            <p className="text-lg text-purple">{data.firstName}</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Adresse email</p>
            <p className="text-lg text-purple">{data.email}</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">
              Numéro de téléphone
            </p>
            <p className="text-lg text-purple">{data.phone}</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Solde</p>
            <p className="text-lg text-purple">{data.balance} €</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">
              Numbre d'Artctions détenues
            </p>
            <p className="text-lg text-purple">{data.nb_artction}</p>
          </div>
          <div>
            <button
              className="bg-black py-2 px-4 font-serif2 text-white rounded hover:bg-black/90"
              onClick={() => navigate("/adminuser", { state: data })}
            >
              <p>Plus d'informations</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
