import React, { useState } from "react";
import { BiChevronDown, BiChevronUp, BiSearch } from "react-icons/bi";
import { HiChevronDown } from "react-icons/hi";
import AdminFooter from "../../components/admin/adminFooter";
import AdminNavigationBar from "../../components/admin/adminNavigationBar";
import AdminUsersListComponent from "../../components/admin/adminNotValidatedUsers";
import AdminUsersList from "../../components/admin/adminUsersList";

export default function AdminUsers() {
  const [seeMoreVerification, setSeeMoreVerification] = useState(false);
  const [seeMoreUsers, setSeeMoreUsers] = useState(false);

  // data sample users validation
  const dataValid = [
    {
      lastName: "DOE",
      firstName: "John",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      isIbanValid: false,
    },
    {
      lastName: "DOE",
      firstName: "John2",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      isIbanValid: true,
    },
    {
      lastName: "DOE",
      firstName: "John3",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      isIbanValid: false,
    },
    {
      lastName: "DOE",
      firstName: "John4",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      isIbanValid: true,
    },
    {
      lastName: "DOE",
      firstName: "John4",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      isIbanValid: true,
    },
  ];

  // data sample users list
  const dataUsersList = [
    {
      user_id: 1,
      lastName: "DOE",
      firstName: "John",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      balance: "233",
      nb_artction: "233",
    },
    {
      user_id: 2,
      lastName: "DOE",
      firstName: "John2",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      balance: "233",
      nb_artction: "233",
    },
    {
      user_id: 3,
      lastName: "DOE",
      firstName: "John3",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      balance: "233",
      nb_artction: "233",
    },
    {
      user_id: 4,
      lastName: "DOE",
      firstName: "John4",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      balance: "233",
      nb_artction: "233",
    },
    {
      user_id: 5,
      lastName: "DOE",
      firstName: "John4",
      email: "john@doe.com",
      address: "290 testestest",
      phone: "0604030205",
      country: "France",
      balance: "233",
      nb_artction: "233",
    },
  ];

  return (
    <div>
      <AdminNavigationBar />
      <div className="mx-36 my-10 bg-white">
        <p className="font-serif2 text-purple">
          Utilisateurs en attente de vérification
        </p>
        <div className="mb-10">
          <div className="space-y-6">
            {!seeMoreVerification
              ? dataValid
                  .slice(0, 3)
                  .map((item, index) => (
                    <AdminUsersListComponent key={index} data={item} />
                  ))
              : dataValid.map((item, index) => (
                  <AdminUsersListComponent key={index} data={item} />
                ))}
          </div>
          <div className="flex justify-center my-2">
            <button
              className="flex items-center font-serif2 text-purple"
              onClick={() => setSeeMoreVerification(!seeMoreVerification)}
            >
              {!seeMoreVerification ? (
                <div className="flex items-center">
                  Voir plus
                  <BiChevronDown />
                </div>
              ) : (
                <div className="flex items-center">
                  Voir moins
                  <BiChevronUp />
                </div>
              )}
            </button>
          </div>
        </div>
        <p className="font-serif2 text-purple mb-px">Utilisateurs</p>
        <div className="space-y-3">
          <div className="flex items-center border rounded-md w-fit border-light-purple">
            <BiSearch color="#d6d6d6" size={25} className="pl-2" />
            <input
              placeholder="Rechercher..."
              className="p-2 pr-40 placeholder-[#d6d6d6] rounded-md"
            />
          </div>
          {!seeMoreUsers
            ? dataUsersList
                .slice(0, 3)
                .map((item, index) => (
                  <AdminUsersList data={item} key={index} />
                ))
            : dataUsersList.map((item, index) => (
                <AdminUsersList data={item} key={index} />
              ))}
          <div className="flex justify-center my-2">
            <button
              className="flex items-center font-serif2 text-purple"
              onClick={() => setSeeMoreUsers(!seeMoreUsers)}
            >
              {!seeMoreUsers ? (
                <div className="flex items-center">
                  Voir plus
                  <BiChevronDown />
                </div>
              ) : (
                <div className="flex items-center">
                  Voir moins
                  <BiChevronUp />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
