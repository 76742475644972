import { useContext, useEffect } from "react";
import FilterContext from "../context/FilterContext";
import { useLocation } from "react-router-dom";

function useFilter() {
  const { filter, setFilter, defaultState } = useContext(FilterContext);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/market" || location.pathname !== "/oeuvre") {
      localStorage.removeItem("filter");
    } else {
      localStorage.setItem("filter", JSON.stringify(filter));
    }
  }, [filter, location.pathname]);

  useEffect(() => {
    const savedFilter = JSON.parse(localStorage.getItem("filter"));
    if (savedFilter) {
      setFilter(savedFilter);
    } else {
      setFilter(defaultState);
    }
  }, []);

  return useContext(FilterContext);
}

export default useFilter;
