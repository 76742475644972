import React, { useState } from "react";
import { BiChevronDown, BiChevronUp, BiSearch } from "react-icons/bi";
import { HiChevronDown } from "react-icons/hi";
import AdminFooter from "../../components/admin/adminFooter";
import AdminNavigationBar from "../../components/admin/adminNavigationBar";
import AdminOrderList from "../../components/admin/adminOrderList";

export default function AdminAllOrders() {
  const [seeMore, setSeeMore] = useState(false);

  const dataOrders = [
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "1000",
      nb_artction: "10",
      artction_price: "100",
      expire_date: "2021-12-31",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "2314",
      nb_artction: "10",
      artction_price: "100",
      expire_date: "2021-12-31",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "546",
      nb_artction: "10",
      artction_price: "100",
      expire_date: "2021-12-23",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
    {
      lastName: "Doe",
      order: "En attente",
      totalPrice: "36",
      nb_artction: "10",
      artction_price: "10000000",
      expire_date: "2021-12-12",
    },
  ];

  return (
    <div>
      <AdminNavigationBar />
      <div className="mx-36 my-10 bg-white">
        <p className="font-serif2 text-purple mb-px">Ordres passés</p>
        <div className="space-y-3">
          <div className="flex space-x-10">
            <div className="flex items-center border rounded-md w-fit border-light-purple">
              <BiSearch color="#d6d6d6" size={25} className="pl-2" />
              <input
                placeholder="Veuillez entrer une date, un nom d'utilisateur ou une oeuvre..."
                className="p-2 pr-48 placeholder-[#d6d6d6] rounded-md"
              />
            </div>
            <select className="border border-purple/60 rounded text-purple font-serif2 px-10 text-center">
              <option>En attente</option>
              <option>Expiré</option>
              <option>Executé</option>
              <option>Executé en partie</option>
            </select>
            <select className="border border-purple/60 rounded text-purple font-serif2 px-10">
              <option>Plus récent</option>
              <option>Plus ancien</option>
            </select>
            <div>
              <button className="bg-black py-2 px-10 h-full font-serif2 text-white rounded hover:bg-black/90">
                <p>Rechercher</p>
              </button>
            </div>
          </div>
          {!seeMore
            ? dataOrders
                .slice(0, 8)
                .map((data, index) => (
                  <AdminOrderList data={data} key={index} />
                ))
            : dataOrders.map((data, index) => (
                <AdminOrderList data={data} key={index} />
              ))}
          <div className="flex justify-center my-2">
            <button
              className="flex items-center font-serif2 text-purple"
              onClick={() => setSeeMore(!seeMore)}
            >
              {!seeMore ? (
                <div className="flex items-center">
                  Voir plus
                  <BiChevronDown />
                </div>
              ) : (
                <div className="flex items-center">
                  Voir moins
                  <BiChevronUp />
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
