import React, { useEffect, useState } from "react";
import { FiTrendingDown, FiTrendingUp } from "react-icons/fi";
import { GrPrevious, GrNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

export default function CarouselHome({ data }) {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [slideIn, setSlideIn] = useState(false);
  const [slideDirection, setSlideDirection] = useState("left");
  const navigate = useNavigate();

  useEffect(() => {
    setSlideIn(false);
  }, [currentIndex]);

  const getPrevIndex = () => {
    return currentIndex === 0 ? data.length - 1 : currentIndex - 1;
  };

  const getNextIndex = () => {
    return currentIndex === data.length - 1 ? 0 : currentIndex + 1;
  };

  return (
    <div>
      <div className="flex">
        {data.map((item, index) => {
          const isActive = (currentIndex + data.length) % data.length === index;
          const isPrev = (getPrevIndex() + data.length) % data.length === index;
          const isNext = (getNextIndex() + data.length) % data.length === index;

          return (
            <div
              key={index}
              className={`${
                isActive
                  ? "scale-75 md:scale-90 lg:scale-100"
                  : isPrev || isNext
                  ? "lg:scale-90 lg:opacity-70 hidden lg:flex"
                  : "hidden"
              } ${
                slideIn
                  ? slideDirection === "left"
                    ? `slide-in-left`
                    : `slide-in-right`
                  : ""
              } `}
            >
              <div className="flex flex-col items-center" key={index}>
                <div className="w-[40em] my-5">
                  <div className="bg-black h-80 rounded-lg"></div>
                  <div className="flex justify-between font-serif2 mt-2">
                    <div>
                      <p className="text-xl font-bold text-black">
                        {item.title}
                      </p>
                      <p className=" text-purple w-2/3">{item.description}</p>
                    </div>
                    <div className="flex flex-col justify-between w-1/4 h-20">
                      <div className="flex flex-col items-end">
                        <p className="text-purple">Variation 1S</p>
                        {item.evolution >= 0 ? (
                          <div className="flex items-center space-x-1 text-green text-lg">
                            <FiTrendingUp />
                            <p>{item.evolution} %</p>
                          </div>
                        ) : (
                          <div className="flex items-center space-x-1 text-red text-lg">
                            <FiTrendingDown />
                            <p>{item.evolution} %</p>
                          </div>
                        )}
                      </div>
                      <a
                        className="bg-black text-white cursor-pointer text-center rounded text-[1.2em] py-[1.5px] font-serif"
                        onClick={() =>
                          navigate("/oeuvre", { state: item.work_id })
                        }
                      >
                        Plus d'infos
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex items-center ">
          <button
            onClick={() => {
              setSlideDirection("left");
              setSlideIn(true);
              setCurrentIndex(getPrevIndex());
            }}
            className="rounded-full border border-even-lighter-gray bg-white p-2 absolute md:left-20 left-5"
          >
            <GrPrevious size={30} color="#D6D6D6" />
          </button>
          <button
            onClick={() => {
              setSlideDirection("right");
              setSlideIn(true);
              setCurrentIndex(getNextIndex());
            }}
            className="rounded-full border border-even-lighter-gray bg-white p-2 absolute md:right-20 right-5"
          >
            <GrNext size={30} color="#D6D6D6" />
          </button>
        </div>
      </div>
      <div className="flex justify-center -mt-5 md:mt-0">
        {currentIndex === 0 ? (
          <div className="space-x-3">
            <button className="rounded-full bg-purple h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
          </div>
        ) : currentIndex === 1 ? (
          <div className="space-x-3">
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-purple h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
          </div>
        ) : currentIndex === 2 ? (
          <div className="space-x-3">
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-purple h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
          </div>
        ) : (
          <div className="space-x-3">
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-even-lighter-gray h-3 w-3"></button>
            <button className="rounded-full bg-purple h-3 w-3"></button>
          </div>
        )}
      </div>
    </div>
  );
}
