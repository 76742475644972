import React, { useState } from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import useFilter from "../../hooks/useFilter";

export default function BasicFilters() {
  const [hideBasicFilters, setHideBasicFilters] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("sellDateASC");

  const { filter, setFilter } = useFilter();

  function handleFilterChange(e) {
    setSelectedFilter(e.target.value || filter.basicFilters);
    setFilter((prevFilter) => ({
      ...prevFilter,
      basicFilters: e.target.value,
    }));
    console.log(e.target.value);
    return e.target.value;
  }

  return (
    <div>
      {hideBasicFilters ? (
        <div className="flex justify-between mb-2">
          <button
            onClick={() => {
              setHideBasicFilters(!hideBasicFilters);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">Filtrer par</p>
            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div className="mb-6">
          <div className="">
            <button
              onClick={() => {
                setHideBasicFilters(!hideBasicFilters);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">Filtrer par</p>
              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px">
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "sellDateASC"}
                    value="sellDateASC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none checked"
                  />

                  <span className="label-text ml-2 text-[#444444]">
                    Date de la mise en vente
                  </span>
                </div>
                <span className="label-text text-[#A5A5A5] ">Plus récent</span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "sellDateDESC"}
                    value="sellDateDESC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none checked"
                  />

                  <span className="label-text ml-2 text-[#444444]">
                    Date de la mise en vente
                  </span>
                </div>
                <span className="label-text text-[#A5A5A5] ">Plus ancien</span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "alphaASC"}
                    value="alphaASC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none checked"
                  />

                  <span className="label-text ml-2 text-[#444444]">
                    Ordre Alphabétique
                  </span>
                </div>
                <span className="label-text text-[#A5A5A5] ">A - Z</span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "alphaDESC"}
                    value="alphaDESC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none"
                  />
                  <span className="label-text ml-2 text-[#444444]">
                    Ordre Alphabétique
                  </span>
                </div>
                <span className="label-text text-[#A5A5A5]">Z - A</span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer ">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "evoPriceASC"}
                    value="evoPriceASC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none"
                  />
                  <span className="label-text ml-2 text-[#444444]">
                    Évolution du prix sur une semaine
                  </span>
                </div>
                <BsArrowDown color={"#A5A5A5"} className="mr-2" />
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "evoPriceDESC"}
                    value="evoPriceDESC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none"
                  />
                  <span className="label-text ml-2 text-[#444444]">
                    Évolution du prix sur une semaine
                  </span>
                </div>
                <BsArrowUp color={"#A5A5A5"} className="mr-2" />
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "nbNotSoldASC"}
                    value="nbNotSoldASC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none"
                  />
                  <span className="label-text ml-2 text-[#444444]">
                    Nombre d'Artctions non vendues
                  </span>
                </div>
                <BsArrowDown color={"#A5A5A5"} className="mr-2" />
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center justify-between cursor-pointer">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="radio-1"
                    onChange={handleFilterChange}
                    checked={selectedFilter === "nbNotSoldDESC"}
                    value="nbNotSoldDESC"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none"
                  />
                  <span className="label-text ml-2 text-[#444444]">
                    Nombre d'Artctions non vendues
                  </span>
                </div>
                <BsArrowUp color={"#A5A5A5"} className="mr-2" />
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
