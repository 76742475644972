import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import instance from "../../api/axios";

export default function ArtPeriod() {
  const [hideArtPeriod, setHideArtPeriod] = useState(false);
  const [artPeriodInfo, setArtPeriodInfo] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [query, setQuery] = useState("");

  const { t } = useTranslation("filter");
  const periods = [
    `${t("antiquity")}`,
    `${t("middleAges")}`,
    `${t("stoneAges")}`,
    `${t("15")}`,
    `${t("16")}`,
    `${t("17")}`,
    `${t("18")}`,
    `${t("19")}`,
    `${t("20")}`,
    `${t("21")}`,
    `${t("1900_10")}`,
    `${t("1910_20")}`,
    `${t("1920_30")}`,
    `${t("1930_40")}`,
    `${t("1950_60")}`,
    `${t("1960_70")}`,
    `${t("1970_80")}`,
    `${t("1980_90")}`,
    `${t("1990_00")}`,
    `${t("2000_10")}`,
    `${t("2000_20")}`,
    `${t("2020_present")}`,
  ];

  function getArtPeriod() {
    instance
      .get("/work/market?lang=fr&page=0", {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setArtPeriodInfo(res.data);
        // setFiltered(res.data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getArtPeriod();
  }, []);

  return (
    <div>
      {hideArtPeriod ? (
        <div className="mb-2">
          <button
            onClick={() => {
              setHideArtPeriod(false);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">
              Période Artistique
            </p>

            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div>
          <div className="">
            <button
              onClick={() => {
                setHideArtPeriod(true);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">
                Période Artistique
              </p>

              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px mb-6">
            <div>
              <input
                type="text"
                placeholder="Rechercher une période"
                className="input input-bordered input-sm w-4/5 rounded-md max-w-xs bg-white"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {seeMore
              ? periods
                  .filter((item) => {
                    if (query === "") {
                      return periods;
                    } else if (
                      item.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return periods;
                    }
                  })
                  .map((item) => (
                    <div className="my-2" key={item}>
                      <label className="flex flex-row justify-between items-center cursor-pointer ">
                        <div className="flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4 border-[#C7C7C7] checked:bg-purple checked"
                          />

                          <span className="label-text ml-2 text-[#444444]">
                            {item}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))
              : periods
                  .filter((item) => {
                    if (query === "") {
                      return periods;
                    } else if (
                      item.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return periods;
                    }
                  })
                  .slice(0, 5)
                  .map((item) => (
                    <div className="my-2" key={item}>
                      <label className="flex flex-row justify-between items-center cursor-pointer ">
                        <div className="flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4 border-[#C7C7C7] checked:bg-purple checked"
                          />

                          <span className="label-text ml-2 text-[#444444]">
                            {item}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}

            <div className="flex flex-col items-center text-[#C4c4c4]">
              <button
                className="flex items-center mb-2"
                onClick={() => setSeeMore(!seeMore)}
              >
                {!seeMore ? (
                  <div className="flex items-center">
                    <p>Voir plus</p>
                    <BiChevronDown />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <p>Voir moins</p>
                    <BiChevronUp />
                  </div>
                )}
              </button>
              <span className="h-px w-[80%] bg-[#C4c4c4]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
