import { useContext, useEffect } from "react";
import AuthContext from "../context/AuthContext";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import axios from "../api/axios";

function useAuth() {
  const { auth, setIsAuthentificated, setUser, getUserInfo } =
    useContext(AuthContext);

  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    if (accessToken === undefined) {
      setIsAuthentificated(() => false);
    } else if (jwtDecode(accessToken).exp < Date.now() / 1000) {
      setIsAuthentificated(() => false);
      console.log("token expired");
    } else {
      setIsAuthentificated(() => true);
      getUserInfo();
      console.log("token success");
    }
  }, []);

  return useContext(AuthContext);
}

export default useAuth;
