import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "../../api/axios";
import Cookies from "js-cookie";
import moment from "moment";

const LANG = localStorage.getItem("lng") || "fr";
let language = "fr";

if (LANG === "fr") {
  language = "fr";
} else if (LANG === "en") {
  language = "en-gb";
} else if (LANG === "ch") {
  language = "zn-cn";
}

function AdminGraphs({ data }) {
  const options = {
    chart: {
      type: "column",
    },
    legend: { enabled: false },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return (
          this.y +
          data.unit +
          "</b><br/>" +
          moment(this.x).locale(language).format("LLL")
        );
      },
    },
    colors: ["#484475"],
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "",
        data: data.data.map((d) => [moment(d.changed_at).valueOf(), d.value]),
      },
    ],
  };

  return (
    <div className="mx-36">
      <p className="font-serif2 text-purple">{data.title}</p>
      <div className="p-10 border rounded-sm border-purple/40">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
}

export default AdminGraphs;
