import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsDownload } from "react-icons/bs";
import axios from "../api/axios";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";

export default function Propose() {
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [adress, setAdress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [dimension, setDimension] = useState("");

  const [fileID, setFileID] = useState(null);
  const [filePhoto, setFilePhoto] = useState(null);
  const [fileDoc, setFileDoc] = useState(null);

  const [owner, setOwner] = useState(false);

  const { t } = useTranslation("propose");

  // function handleFileIDChange(e) {
  //   setFileID(e.target.files[0]);
  // }
  function handleFilePhotoChange(e) {
    setFilePhoto(e.target.files[0]);
  }
  function handleFileDocChange(e) {
    setFileDoc(e.target.files[0]);
  }

  function handleCheckboxChange() {
    setOwner(true);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    // console.log(fileID);
    console.log(filePhoto);
    console.log(fileDoc);

    let formData = new FormData();
    // formData.append("files[]", fileID, JSON.stringify(fileID.name));
    formData.append("files[]", filePhoto, JSON.stringify(filePhoto.name));
    formData.append("files[]", fileDoc, JSON.stringify(fileDoc.name));

    try {
      const response = await axios({
        method: "post",
        url: `/work/proposal`,
        data: formData,
        params: {
          first_name: firstName,
          last_name: surName,
          street_address: adress,
          city_ZIP: zipCode,
          city_name: city,
          country: country,
          email: email,
          phone: phone,
          work_description: description,
          word_dimensions: dimension,
          is_owner: owner,
        },
      });
      console.log("res", response);
    } catch (error) {
      if (error?.code === 403) {
        console.log("email in use");
      } else {
        console.log(error);
      }
    }
  }

  return (
    <div className="bg-white">
      <NavigationBar />
      <div className="lg:mx-36 mx-8 my-20 ">
        <p className="font-serif2 bg-transparent text-purple">
          {t("completeForm")}
        </p>
        <form
          onSubmit={handleSubmit}
          className="border-purple/40 rounded-sm border p-10 flex flex-col items-center lg:items-start"
        >
          {/* <img
            src={Shape3}
            alt=""
            className="absolute top-72 -right-56 -z-10 w-96 bg-cover"
          />
          <img
            src={Shape4}
            alt=""
            className="absolute top-20 -left-56 -z-10 w-96 bg-cover"
          /> */}
          <div className="w-96 lg:w-[80%]">
            <div className="lg:space-x-4 xl:w-[40%] lg:w-[60%] flex flex-col lg:flex-row space-y-2 lg:space-y-0">
              <TextField
                id="outlined-basic"
                type="text"
                label={t("firstName")}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                type="text"
                label={t("surName")}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={surName}
                onChange={(event) => setSurName(event.target.value)}
              />
            </div>
            <div className="lg:space-x-4 space-y-2 xl:w-[40%] lg:w-[60%] lg:space-y-0 mt-4 flex flex-col lg:flex-row">
              <TextField
                id="outlined-basic"
                type="text"
                label={t("adress")}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={adress}
                onChange={(event) => setAdress(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                type="text"
                label={t("zipCode")}
                variant="outlined"
                size="small"
                style={{ width: "40%" }}
                value={zipCode}
                onChange={(event) => setZipCode(event.target.value)}
              />
            </div>
            <div className="lg:space-x-4 xl:w-[40%] lg:w-[60%] space-y-2 lg:space-y-0 my-4 flex flex-col lg:flex-row">
              <TextField
                id="outlined-basic"
                type="text"
                label={t("city")}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={city}
                onChange={(event) => setCity(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                type="text"
                label={t("country")}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={country}
                onChange={(event) => setCountry(event.target.value)}
              />
            </div>
            <div className="flex xl:w-[40%] lg:w-[60%] flex-col lg:space-y-4 space-y-2 ">
              <TextField
                id="outlined-basic"
                type="text"
                label={t("email")}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                id="outlined-basic"
                type="text"
                label={t("phone")}
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
            {/* <div className="flex flex-col my-6 ">
            <input
              type="file"
              onChange={(e) => handleFileIDChange(e)}
              id="getID"
              className="overflow-hidden absolute w-[0.1px] h-[0.1px] opacity-0 -z-[1]"
              accept=".png, .jpg, .jpeg, .pdf, image/jpeg, image/png, application/pdf"
            />
            <label
              htmlFor="getID"
              className="btn w-72 h-10 gap-2 text-black bg-transparent hover:text-white hover:bg-gray-dark normal-case font-serif2 font-normal"
            >
              <BsDownload size={18} />
              {t("importID")}
            </label>
            <p className="font-serif2 text-even-lighter-gray">
              {t("importantMessage")}
            </p>
          </div> */}
            <div className="flex my-2 font-serif2 text-purple/70">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Vous êtes le propriétaire"
                    control={<Radio />}
                    label={t("youAreTheOwner")}
                    onChange={handleCheckboxChange}
                  />
                  <FormControlLabel
                    value="Vous vendez pour le compte d'un tiers"
                    control={<Radio />}
                    label={t("sellingForOther")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <textarea
              className="w-full rounded border border-purple/40 p-2 mb-8"
              placeholder={t("workHistoryPlaceholder")}
              form="history"
              type="text"
              rows="4"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
            <textarea
              className="w-full rounded border border-purple/40 p-2"
              placeholder={t("dimensionPlaceholder")}
              form="history"
              type="text"
              value={dimension}
              onChange={(event) => setDimension(event.target.value)}
            />
            <div className="flex flex-col my-6 space-y-6 font-serif2 ">
              <div className="flex items-center  text-purple/50 ">
                <input
                  type="file"
                  onChange={(e) => handleFilePhotoChange(e)}
                  id="getPicture"
                  className="overflow-hidden absolute w-[0.1px] h-[0.1px] opacity-0 -z-[1]"
                  accept=".png, .jpg, .jpeg, .pdf, image/jpeg, image/png, application/pdf"
                />
                <label
                  htmlFor="getPicture"
                  className="btn lg:w-72 w-full h-10 gap-2 text-black bg-transparent hover:text-white hover:bg-gray-dark normal-case font-serif2 font-normal"
                >
                  <BsDownload size={18} />
                  {t("importPictures")}
                </label>
              </div>
              <div className="flex items-center text-purple/50 ">
                <input
                  type="file"
                  onChange={(e) => handleFileDocChange(e)}
                  id="getDoc"
                  className="overflow-hidden absolute w-[0.1px] h-[0.1px] opacity-0 -z-[1]"
                  accept=".png, .jpg, .jpeg, .pdf, image/jpeg, image/png, application/pdf"
                />
                <label
                  htmlFor="getDoc"
                  className="btn lg:w-72 w-full h-10 gap-2 text-black bg-transparent hover:text-white hover:bg-gray-dark normal-case font-serif2 font-normal"
                >
                  <BsDownload size={18} />
                  {t("importDocuments")}
                </label>
              </div>
              <div className="flex justify-start ">
                <button
                  type="submit"
                  className="btn bg-gray-dark text-white font-serif2 normal-case font-normal w-full lg:w-56 text-lg px-10"
                >
                  {t("send")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <div className="bg-black h-2/3 absolute w-full -z-10 top-2/3">
        <img src={Shape1} alt="" className="absolute  top-40 w-96 bg-cover" />
        <img
          src={Shape2}
          alt=""
          className="absolute top-20    -right-32 w-96 bg-cover"
        />
      </div> */}

      <Footer />
    </div>
  );
}
