import axios from "axios";

export default axios.create({
  baseURL: "https://api.tibermont-antiquites-2.juniorisep.com",
});

export const axiosPrivate = axios.create({
  baseURL: "http://localhost:3000",
  headers: { "Content-type": "application/json" },
  withCredentials: true,
});
