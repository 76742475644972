import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "../api/axios";
import Footer from "../components/footer";
import NavigationBarSignin from "../components/navigationbar-signin";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const { t } = useTranslation("forgotpassword");

  function sendEmail(email) {
    instance
      .post(`/auth/reset?email=${email}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((e) => console.log(e));
  }

  return (
    <div className="bg-white h-screen flex flex-col justify-between">
      <NavigationBarSignin />
      <div className="xl:ml-80 lg:ml-56 mx-10 mb-20 w-[80%] xl:w-[25%] lg:w-[35%]">
        <div className="mb-10 text-center lg:text-left">
          <p className="font-serif text-black lg:text-5xl text-4xl">
            {t("resetPassword")}
          </p>
          <p className="font-serif2 text-even-lighter-gray lg:text-xl text-base mb-5">
            {t("resetMessage")}
          </p>
          <TextField
            id="outlined-basic"
            type="email"
            label={t("emailPlaceholder")}
            variant="outlined"
            autoComplete="current-email"
            style={{ width: "100%", marginTop: "20px" }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
        </div>
        <div className="mb-10 mt-5 rounded px-7 py-5 border border-even-lighter-gray w-72 flex flex-row justify-start space-x-3 text-black">
          <input type="checkbox" />
          <p>I'm not a robot</p>
        </div>
        <div className="flex justify-end w-full">
          <button
            className="btn bg-gray-dark text-white w-full font-serif2 normal-case font-normal mt-10 text-lg px-10"
            onClick={sendEmail(email)}
          >
            {t("send")}
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
