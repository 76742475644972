import React, { useEffect, useState } from "react";
import AdminFooter from "../../components/admin/adminFooter";
import AdminGraph from "../../components/admin/adminGraphs";
import AdminNavigationBar from "../../components/admin/adminNavigationBar";
import AdminStat from "../../components/admin/adminStat";
import Chart2 from "../../components/admin/adminGraphs";
import axios from "../../api/axios";
import Cookies from "js-cookie";
import moment from "moment";

export default function AdminHome() {
  const [graphsStats, setGraphsStats] = useState([]);
  const [keyFiguresStats, setKeyFiguresStats] = useState([]);

  async function getAdminStats() {
    const res = await axios.get("/work/stats", {
      headers: {
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    });
    try {
      const data = Object.values(res?.data.graphs);
      setGraphsStats(data);
      const keyFigures = Object.values(res?.data.key_figures);
      setKeyFiguresStats(keyFigures);
      // console.log(graphsStats);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getAdminStats();
  }, []);
  return (
    <div>
      <AdminNavigationBar />
      <div className="mx-36 bg-white mb-20">
        <p className="text-4xl text-purple font-serif my-6">Statistiques</p>
        <div className="mx-36">
          <p className="font-serif2 text-purple">Données rapides</p>
          <div className="flex mb-10 space-x-10">
            {keyFiguresStats?.map((item, index) => (
              <AdminStat data={item} key={index} />
            ))}
          </div>
        </div>
        <div className="space-y-20">
          {graphsStats?.map((item, index) => (
            <AdminGraph key={index} data={item} />
          ))}
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
