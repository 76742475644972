import "./App.css";

import Home from "./pages/home";
import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
  Link,
  Navigate,
  Outlet,
} from "react-router-dom";
import PageNotFound from "./components/pagenotfound/pagenotfound";
import Market from "./pages/market";
import SignUp from "./pages/signup";
import MyAccount from "./pages/myaccount";
import Propose from "./pages/propose";
import Contact from "./pages/contact";
import Help from "./pages/help";
import SignIn2 from "./pages/signin";
import Wallet from "./pages/wallet";
import Oeuvres from "./pages/oeuvres";
import useAuth from "./hooks/useAuth";
import Disclosures from "./pages/disclosures";
import Terms from "./pages/terms";
import ForgotPassword from "./pages/forgotpassword";
import NewPassord from "./pages/newpassword";
import AdminSignIn from "./pages/admin/adminSignin";
import AdminHome from "./pages/admin/adminHome";
import AdminOeuvre from "./pages/admin/adminOeuvre";
import AdminUsers from "./pages/admin/adminUsers";
import AdminAllOrders from "./pages/admin/adminAllOrders";
import AdminUser from "./components/admin/adminUser";
import ConfirmRegister from "./pages/confirmRegister";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/market" element={<Market />} />
        <Route path="/oeuvre" element={<Oeuvres />} />
        <Route path="/propose" element={<Propose />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/help" element={<Help />} />
        <Route path="/disclosures" element={<Disclosures />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/signin" element={<SignIn2 />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/newpassword" element={<NewPassord />} />
        <Route path="/confirmRegister" element={<ConfirmRegister />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route path="myaccount" element={<MyAccount />} />
          <Route path="wallet" element={<Wallet />} />
        </Route>

        {/* <Route path="/" element={<AdminRoute />}> */}
        <Route path="adminhome" element={<AdminHome />} />
        <Route path="adminoeuvre" element={<AdminOeuvre />} />
        <Route path="adminusers" element={<AdminUsers />} />
        <Route path="adminallorders" element={<AdminAllOrders />} />
        <Route path="adminuser" element={<AdminUser />} />
        {/* </Route> */}

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <main className="App">
      <Outlet />
    </main>
  );
}

function PrivateRoute() {
  const { isAuthentificated, user } = useAuth();
  if (isAuthentificated === null) {
    return (
      <p className="flex items-center justify-center h-screen text-3xl font-serif">
        Chargement en cours...
      </p>
    );
  }
  console.log(isAuthentificated);
  return !isAuthentificated ? <Navigate to="/signin" /> : <Outlet />;
}

function AdminRoute() {
  const { isAdmin } = useAuth();
  // if (isAdmin === null) {
  //   return (
  //     <p className="flex items-center justify-center h-screen text-3xl font-serif">
  //       Chargement en cours...
  //     </p>
  //   );
  // }
  return !isAdmin ? <Navigate to="/signin" /> : <Outlet />;
}

export default App;
