import React from "react";
import { useState } from "react";
import { BiChevronUp, BiChevronDown, BiChevronRight } from "react-icons/bi";
import useFilter from "../../hooks/useFilter";

const MAX_ARTCTION_PRICE = 5000;

export default function ArtctionPrice() {
  const [hideArtctionPriceFilters, setHideArtctionPriceFilters] =
    useState(false);
  const [minArtctionPrice, setMinArtctionPrice] = useState(0);
  const [maxArtctionPrice, setMaxArtctionPrice] = useState(MAX_ARTCTION_PRICE);

  const [selectedOption, setSelectedOption] = useState("allPrices");
  const { filter, setFilter } = useFilter();

  function handleOptionChange(e) {
    setSelectedOption(e.target.value || filter.artctionType);
    setFilter((prevFilter) => ({
      ...prevFilter,
      artctionType: e.target.value,
    }));
    console.log(e.target.value);
    return e.target.value;
  }

  return (
    <div>
      {hideArtctionPriceFilters ? (
        <div className="mb-2">
          <button
            onClick={() => {
              setHideArtctionPriceFilters(false);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">
              Prix de l'artction
            </p>

            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div>
          <div className="">
            <button
              onClick={() => {
                setHideArtctionPriceFilters(true);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">
                Prix de l'artction
              </p>
              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px mb-6">
            <div className=" mb-2 ">
              <label className="flex flex-row items-center cursor-pointer ">
                <input
                  type="radio"
                  name="radio-3"
                  value="allPrices"
                  onChange={handleOptionChange}
                  checked={selectedOption === "allPrices"}
                  className="radio w-4 h-4 border-[#C7C7C7]  checked:bg-purple checked"
                />

                <span className="label-text ml-2 text-[#444444]">
                  Tous les prix
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-3"
                  value="minus1000"
                  onChange={handleOptionChange}
                  checked={selectedOption === "minus1000"}
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444] ">
                  Moins de 1000€
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-3"
                  value="minus2000"
                  onChange={handleOptionChange}
                  checked={selectedOption === "minus2000"}
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444]">
                  Moins de 2000€
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-3"
                  value="minus3000"
                  onChange={handleOptionChange}
                  checked={selectedOption === "minus3000"}
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444]">
                  Moins de 3000€
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-3"
                  value="4000plus"
                  onChange={handleOptionChange}
                  checked={selectedOption === "4000plus"}
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444]">
                  4000€ et plus
                </span>
              </label>
            </div>
            <div className="flex flex-row items-center mb-2 mt-8 space-x-4">
              <input
                type="text"
                placeholder="EUR min."
                className="input input-bordered w-20 text-2xs max-w-xs mr-4 border-[#C7C7C7] bg-white"
                onChange={(e) => setMinArtctionPrice(e.target.value)}
              />
              à
              <input
                type="text"
                placeholder="EUR max."
                className="input input-bordered w-20 text-2xs max-w-xs border-[#C7C7C7] bg-white"
                onChange={(e) => setMaxArtctionPrice(e.target.value)}
              />
              <button className=" border-even-lighter-gray">
                <BiChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
