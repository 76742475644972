import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoOptionsSharp } from "react-icons/io5";
import axios from "../api/axios";
import ArtObject from "../components/artobject/artobject";
import Filter from "../components/filters/filter";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import useFilter from "../hooks/useFilter";

const LANG = localStorage.getItem("lng") || "fr";
const PAGE = 0;

export default function Market() {
  const [workInfo, setWorkInfo] = useState([]);
  const [openFilterMobile, setOpenFilterMobile] = useState(false);
  const { t } = useTranslation("market");

  const { filter, setFilter } = useFilter();

  const FilteredItems = workInfo.filter(
    (item) =>
      filter.artctionType === "allTypes" ||
      item.is_introductory === (filter.artctionType === "introductoryArtction")
    // &&
    // (filter.basicFilters === [] ||
    //   item.avg_price === (filter.basicFilters === "sellDateASC"))
  );

  function getMarket() {
    axios
      .get(`/work/market?lang=${LANG}&page=${PAGE}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setWorkInfo(res.data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getMarket();
  }, []);

  if (workInfo.length === 0) {
    return (
      <p className="flex bg-white    items-center justify-center h-screen text-3xl font-serif">
        Chargement en cours...
      </p>
    );
  } else {
    return (
      <div className="bg-white h-full">
        <NavigationBar />
        <div className="flex lg:flex-row flex-col justify-center items-center lg:items-start  mt-10 ">
          <div className="w-[80%] flex justify-end lg:hidden">
            <button
              className="border rounded p-2 border-dark-black/30"
              onClick={() => setOpenFilterMobile(!openFilterMobile)}
            >
              <IoOptionsSharp size={26} color="#1C1C1C" />
            </button>
          </div>
          <div className={`${!openFilterMobile ? "hidden" : "block"} lg:flex`}>
            <Filter />
          </div>
          <div className="grid xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 w-fit gap-x-20 gap-y-8 my-8 h-fit grid-cols-1">
            {FilteredItems.map((item) => (
              <ArtObject
                key={item.work_id}
                workId={item.work_id}
                title={item.title}
                isIntroductory={item.is_introductory}
                avgPrice={Number(item.avg_price)}
                nbAvailable={item.nb_available}
                evolution={item.evolution}
                image={"image"}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
