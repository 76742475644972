import { TextField } from "@mui/material";
import React, { useState } from "react";

export default function AdminAddOeuvre() {
  const [french, setFrench] = useState("");
  const [english, setEnglish] = useState("");
  const [chinese, setChinese] = useState("");
  const [baseArtction, setBaseArtction] = useState("");
  const [totalArtction, setTotalArtction] = useState("");

  return (
    <div>
      <p className="font-serif2 text-purple">Ajouter une oeuvre</p>
      <div className="border-purple/40 rounded-sm border p-10 space-y-6">
        <div className="flex justify-between">
          <TextField
            id="outlined-basic"
            type="text"
            label="Titre en français"
            variant="outlined"
            size="small"
            style={{ width: "450px" }}
            value={french}
            onChange={(event) => setFrench(event.target.value)}
          />
          <TextField
            id="outlined-basic"
            type="text"
            label="Titre en anglais"
            variant="outlined"
            size="small"
            style={{ width: "450px" }}
            value={english}
            onChange={(event) => setEnglish(event.target.value)}
          />
          <TextField
            id="outlined-basic"
            type="text"
            label="Titre en chinois"
            variant="outlined"
            size="small"
            style={{ width: "450px" }}
            value={chinese}
            onChange={(event) => setChinese(event.target.value)}
          />
        </div>
        <div className="space-y-4">
          <textarea
            className="w-full rounded border border-purple/40 p-2"
            placeholder="Description en français..."
            form="history"
            type="text"
            rows="4"
          />
          <textarea
            className="w-full rounded border border-purple/40 p-2"
            placeholder="Description en anglais..."
            form="history"
            type="text"
            rows="4"
          />
          <textarea
            className="w-full rounded border border-purple/40 p-2"
            placeholder="Description en chinois..."
            form="history"
            type="text"
            rows="4"
          />
        </div>
        <div className="space-x-10 ">
          <TextField
            id="outlined-basic"
            type="text"
            label="Montant initial de l'Artction"
            variant="outlined"
            size="small"
            style={{ width: "300px" }}
            value={baseArtction}
            onChange={(event) => setBaseArtction(event.target.value)}
          />
          <TextField
            id="outlined-basic"
            type="text"
            label="Nombre total d'Artctions"
            variant="outlined"
            size="small"
            style={{ width: "300px" }}
            value={totalArtction}
            onChange={(event) => setTotalArtction(event.target.value)}
          />
        </div>
        <button className="btn bg-gray-dark text-white font-serif2 normal-case font-normal text-md px-8">
          Ajouter des photos
        </button>
        <div className="flex space-x-10">
          <div className="flex flex-col items-center w-fit">
            <div className=" bg-light-purple h-32 w-28 rounded " />
            <p className="font-serif2 text-purple">1 - photo.jpg</p>
          </div>
          <div className="flex flex-col items-center w-fit">
            <div className=" bg-light-purple h-32 w-28 rounded " />
            <p className="font-serif2 text-purple">2 - photo.jpg</p>
          </div>
          <div className="flex flex-col items-center w-fit">
            <div className=" bg-light-purple h-32 w-28 rounded " />
            <p className="font-serif2 text-purple">3 - photo.jpg</p>
          </div>
        </div>
        <div className="font-serif2 w-fit space-y-1">
          <p className="text-even-lighter-gray">Mise en vente par Arction</p>
          <select className="border border-purple/40 text-purple py-px w-full text-center">
            <option>Ordinaire</option>
            <option>Introduction</option>
          </select>
        </div>
        <div className="flex justify-center ">
          <button className="btn bg-gray-dark text-white font-serif2 normal-case font-normal text-md px-12 mt-4">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
}
