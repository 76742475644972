import React from "react";
import useAuth from "../hooks/useAuth";
import OeuvresCo from "../components/oeuvresco";
import OeuvresDeco from "../components/oeuvresdeco";
import { useLocation } from "react-router-dom";

export default function Oeuvres() {
  const { isAuthentificated } = useAuth();
  const { state } = useLocation();
  const workId = state;

  if (isAuthentificated) {
    return <OeuvresCo workId={workId} />;
  } else {
    return <OeuvresDeco workId={workId} />;
  }
}
