import { TextField } from "@mui/material";
import React, { useState } from "react";
import Footer from "../components/footer";
import NavigationBarSignin from "../components/navigationbar-signin";

export default function NewPassord() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <div className="bg-white h-screen flex flex-col justify-between">
      <NavigationBarSignin />
      <div className="ml-80 mb-12">
        <div className="mb-10">
          <p className="font-serif text-black text-5xl">Nouveau mot de passe</p>
          <p className="font-serif2 text-even-lighter-gray text-xl mb-5">
            Veuillez saisir votre nouveau mot de passe
          </p>
          <div className="flex flex-col">
            <TextField
              id="outlined-basic"
              type="password"
              label="Nouveau mot de passe"
              variant="outlined"
              style={{ width: "425px", marginTop: "20px" }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
            <TextField
              id="outlined-basic"
              type="password"
              label="Confirmez votre nouveau mot de passe"
              variant="outlined"
              style={{ width: "425px", marginTop: "20px" }}
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-10 mt-5 rounded px-7 py-5 border border-even-lighter-gray w-72 flex flex-row justify-start space-x-3 text-black">
          <input type="checkbox" />
          <p>I'm not a robot</p>
        </div>
        <button className="btn bg-gray-dark text-white font-serif2 normal-case font-normal text-lg px-10">
          Réinitialiser le mot de passe
        </button>
      </div>
      <Footer />
    </div>
  );
}
