import React, { useState, useEffect } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import axios from "../api/axios";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export default function TransactionHistoryComp({ data }) {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [query, setQuery] = useState("");
  const [seeMore, setSeeMore] = useState(false);

  const { t } = useTranslation("wallet");

  return (
    <div className="rounded-lg py-3 px-5 flex flex-col gap-3 font-serif2 border border-light-purple my-2 bg-white">
      <div className="flex justify-between">
        <div className="text-purple">
          <p className="font-bold text-lg">
            {data.source === "card" ? (
              <p>{t("userCard")}</p>
            ) : data.source === "sales" ? (
              <p>{t("userSell")}</p>
            ) : (
              <p>{t("userTransfer")}</p>
            )}
          </p>
          <p>
            {data.type === "add" ? (
              <p>{t("userAdd")}</p>
            ) : (
              <p>{t("userPull")}</p>
            )}
          </p>
        </div>
        <div className="text-right">
          {data.amount < 0 ? (
            <p className="text-red font-semibold text-xl">{data.amount} €</p>
          ) : (
            <p className="text-green font-semibold text-xl">+{data.amount} €</p>
          )}
        </div>
      </div>
      <div className="flex justify-between items-end">
        <p className="text-green/80 font-semibold text-base">
          {data.is_confirmed === true ? (
            <p className="text-green">{t("userValidate")}</p>
          ) : (
            <p className="text-red">{t("userInProgress")}</p>
          )}
        </p>
        <p className="text-even-lighter-gray">
          {moment(data.changed_at).format("DD/MM/YYYY")}
        </p>
      </div>
    </div>
  );
}
