import React from "react";
import NavigationBar from "../navigationbar";
import Footer from "../footer";

export default function PageNotFound() {
  return (
    <div>
      <NavigationBar />
      <div className="flex items-center flex-col mt-20 text-black bg-white font-serif">
        <div className="mt-20 text-5xl">Error 404 : Page not found</div>
        <p className="text-xl">
          Veuillez vous redirigez vers une page existante.
        </p>
      </div>
    </div>
  );
}
