import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useFilter from "../hooks/useFilter";

export default function NavigationBar() {
  const { isAuthentificated, logout } = useAuth();
  const { setFilter } = useFilter();
  const { t, i18n } = useTranslation("navbar");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [marketMobileOpen, setMarketMobileOpen] = useState(false);
  const [accountMobileOpen, setAccountMobileOpen] = useState(false);
  const [languageMobileOpen, setLanguageMobileOpen] = useState(false);

  window.onresize = function () {
    const width = window.outerWidth;
    if (width > 1024) {
      setMobileOpen(false);
    }
  };

  const onLogoutPressed = () => {
    logout();
  };

  return (
    <div className="navbar bg-gray-dark text-white font-serif ">
      <div className="flex-1 ml-10 xl:ml-36">
        <Link
          to="/"
          className="bg-mobileLogo bg-cover h-5 w-20 xl:bg-fullLogo xl:bg-contain xl:w-96"
        />
      </div>
      <div className="lg:flex mr-16 xl:mr-36 2xl:text-lg xl:text-base lg:text-sm hidden">
        <ul className="flex flex-col lg:flex-row space-x-6 items-center">
          <li className="dropdown dropdown-hover">
            <Link
              to="/market"
              tabIndex="0"
              className="text-white pb-4 before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
            >
              {t("market")}
            </Link>
            <ul
              tabIndex="0"
              className="dropdown-content menu p-2 shadow bg-light-gray w-52 top-10"
            >
              <li className="pb-2">
                <Link
                  to="/market"
                  onClick={() =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      artctionType: "ordindaryArtction",
                    }))
                  }
                >
                  {t("primaryMarket")}
                </Link>
              </li>
              <div className="h-px w-auto bg-[#707070]" />
              <li className="pt-2">
                <Link
                  to="/market"
                  onClick={() =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      artctionType: "introductoryArtction",
                    }))
                  }
                >
                  {t("secondaryMarket")}
                </Link>
              </li>
            </ul>
          </li>
          <li className="">
            <Link
              to="/propose"
              className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
            >
              {t("propose")}
            </Link>
          </li>
          {!isAuthentificated ? (
            <li>
              <Link
                to="/signin"
                className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
              >
                {t("login")}
              </Link>
            </li>
          ) : (
            <li className="dropdown dropdown-hover">
              <Link
                to="/myaccount"
                tabIndex="0"
                className="text-white pb-4 before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
              >
                {t("myAccount")}
              </Link>
              <ul
                tabIndex="0"
                className="dropdown-content menu p-2 shadow bg-light-gray w-52 top-10 divide-y"
              >
                <li className="pb-2 ">
                  <Link to="/myaccount">{t("info")}</Link>
                </li>
                <li className="pt-2 pb-2">
                  <Link to="/wallet">{t("wallet")}</Link>
                </li>
                <li className="pt-2">
                  <Link to="/" onClick={onLogoutPressed}>
                    {t("logout")}
                  </Link>
                </li>
              </ul>
            </li>
          )}
          <div className="h-5 w-px bg-white" />
          <li>
            <Link
              to="/contact"
              className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
            >
              {t("contact")}
            </Link>
          </li>
          <li>
            <Link
              to="/help"
              className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
            >
              {t("help")}
            </Link>
          </li>
          <li className="dropdown dropdown-hover">
            <label
              tabIndex="0"
              className="text-white pb-4 before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
            >
              {t("languages")}
            </label>
            <ul
              tabIndex="0"
              className="dropdown-content menu p-2 shadow bg-light-gray w-32 top-10"
            >
              <li className="pb-2">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "fr");
                    i18n.changeLanguage("fr");
                  }}
                >
                  Français
                </a>
              </li>
              <div className="h-px w-auto bg-[#707070]" />
              <li className="pb-2 pt-2">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "en");
                    i18n.changeLanguage("en");
                  }}
                >
                  English
                </a>
              </li>
              <div className="h-px w-auto bg-[#707070]" />
              <li className="pt-2">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "ch");
                    i18n.changeLanguage("ch");
                  }}
                >
                  中国人
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      {/* Mobile navbar */}
      <div className="mr-10 xl:mr-36 2xl:text-lg xl:text-base lg:text-sm lg:hidden relative">
        <button onClick={() => setMobileOpen(!mobileOpen)}>
          {!mobileOpen ? (
            <div className="space-y-[5px]">
              <div className="h-[2px] w-5 bg-white rounded"></div>
              <div className="h-[2px] w-5 bg-white rounded"></div>
              <div className="h-[2px] w-5 bg-white rounded"></div>
            </div>
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                y1="-0.5"
                x2="20.6625"
                y2="-0.5"
                transform="matrix(0.725953 0.687745 -0.725953 0.687745 0 0.789429)"
                stroke="white"
              />
              <line
                y1="-0.5"
                x2="21.2132"
                y2="-0.5"
                transform="matrix(-0.707107 0.707107 -0.744216 -0.667939 15 0)"
                stroke="white"
              />
            </svg>
          )}
        </button>
      </div>
      <div
        className={`${
          !mobileOpen ? "hidden" : "absolute"
        } bg-black top-16 left-0 z-10 w-full`}
      >
        <ul className="flex flex-col space-y-5 pb-5 items-end w-full text-lg">
          <li className="w-full text-right">
            <Link
              to="/"
              className="text-white pr-12 flex items-center space-x-2 justify-end"
              onClick={() => setMarketMobileOpen(!marketMobileOpen)}
            >
              <div>{t("market")}</div>
              {marketMobileOpen ? (
                <svg
                  width="11"
                  height="6"
                  viewBox="0 0 22 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7071 1.20711L20.7929 10.2929C21.4229 10.9229 20.9767 12 20.0858 12L1.91421 12C1.02331 12 0.577143 10.9229 1.20711 10.2929L10.2929 1.20711C10.6834 0.816582 11.3166 0.816583 11.7071 1.20711Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="11"
                  height="6"
                  viewBox="0 0 22 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2929 11.2929L1.20711 2.20711C0.577142 1.57714 1.02331 0.5 1.91421 0.5H20.0858C20.9767 0.5 21.4229 1.57714 20.7929 2.20711L11.7071 11.2929C11.3166 11.6834 10.6834 11.6834 10.2929 11.2929Z"
                    fill="white"
                  />
                </svg>
              )}
            </Link>
            <ul
              tabIndex="0"
              className={`${
                !marketMobileOpen ? "hidden" : "block"
              } flex flex-col items-end mt-3 pr-12 space-y-2 bg-light-gray w-full text-base `}
            >
              <li className="py-3">
                <Link
                  to="/market"
                  onClick={() =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      artctionType: "ordindaryArtction",
                    }))
                  }
                >
                  {t("primaryMarket")}
                </Link>
              </li>
              <li className="py-3">
                <Link
                  to="/market"
                  onClick={() =>
                    setFilter((prevFilter) => ({
                      ...prevFilter,
                      artctionType: "introductoryArtction",
                    }))
                  }
                >
                  {t("secondaryMarket")}
                </Link>
              </li>
            </ul>
          </li>
          <li className="w-full text-right">
            <Link className="pr-12 w-full" to="/propose">
              {t("propose")}
            </Link>
          </li>
          {!isAuthentificated ? (
            <li className="w-full text-right">
              <Link className="pr-12 w-full" to="/signin">
                {t("login")}
              </Link>
            </li>
          ) : (
            <li className="w-full text-right">
              <span
                onClick={() => setAccountMobileOpen(!accountMobileOpen)}
                className="text-white pr-12 cursor-pointer flex items-center space-x-2 justify-end"
              >
                <div>{t("myAccount")}</div>
                {accountMobileOpen ? (
                  <svg
                    width="11"
                    height="6"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7071 1.20711L20.7929 10.2929C21.4229 10.9229 20.9767 12 20.0858 12L1.91421 12C1.02331 12 0.577143 10.9229 1.20711 10.2929L10.2929 1.20711C10.6834 0.816582 11.3166 0.816583 11.7071 1.20711Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="11"
                    height="6"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2929 11.2929L1.20711 2.20711C0.577142 1.57714 1.02331 0.5 1.91421 0.5H20.0858C20.9767 0.5 21.4229 1.57714 20.7929 2.20711L11.7071 11.2929C11.3166 11.6834 10.6834 11.6834 10.2929 11.2929Z"
                      fill="white"
                    />
                  </svg>
                )}
              </span>
              <ul
                className={`${
                  !accountMobileOpen ? "hidden" : "block"
                } flex flex-col items-end mt-3 pr-12 space-y-2 bg-light-gray w-full text-base`}
              >
                <li className="py-3 ">
                  <Link to="/myaccount">{t("info")}</Link>
                </li>
                <li className="py-3">
                  <Link to="/wallet">{t("wallet")}</Link>
                </li>
                <li className="py-3 text-red">
                  <Link to="/" onClick={onLogoutPressed}>
                    {t("logout")}
                  </Link>
                </li>
              </ul>
            </li>
          )}
          <li className="w-full text-right">
            <Link className="pr-12 w-full" to="/contact">
              {t("contact")}
            </Link>
          </li>
          <li className="w-full text-right">
            <Link className="pr-12 w-full" to="/help">
              {t("help")}
            </Link>
          </li>
          <li className=" text-right w-full">
            <label
              onClick={() => setLanguageMobileOpen(!languageMobileOpen)}
              className="text-white pr-12 cursor-pointer flex items-center space-x-2 justify-end"
            >
              <div>{t("languages")}</div>
              {languageMobileOpen ? (
                <svg
                  width="11"
                  height="6"
                  viewBox="0 0 22 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.7071 1.20711L20.7929 10.2929C21.4229 10.9229 20.9767 12 20.0858 12L1.91421 12C1.02331 12 0.577143 10.9229 1.20711 10.2929L10.2929 1.20711C10.6834 0.816582 11.3166 0.816583 11.7071 1.20711Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="11"
                  height="6"
                  viewBox="0 0 22 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2929 11.2929L1.20711 2.20711C0.577142 1.57714 1.02331 0.5 1.91421 0.5H20.0858C20.9767 0.5 21.4229 1.57714 20.7929 2.20711L11.7071 11.2929C11.3166 11.6834 10.6834 11.6834 10.2929 11.2929Z"
                    fill="white"
                  />
                </svg>
              )}
            </label>
            <ul
              className={`${
                !languageMobileOpen ? "hidden" : "block"
              } flex flex-col items-end mt-3 pr-12 space-y-2 bg-light-gray w-full text-base`}
            >
              <li className="py-3 cursor-pointer">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "fr");
                    i18n.changeLanguage("fr");
                  }}
                >
                  Français
                </a>
              </li>

              <li className="py-3 cursor-pointer">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "en");
                    i18n.changeLanguage("en");
                  }}
                >
                  English
                </a>
              </li>

              <li className="py-3 cursor-pointer">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "ch");
                    i18n.changeLanguage("ch");
                  }}
                >
                  中国人
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
