import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import axios from "../api/axios";

export default function Balance() {
  const [userInfos, setUserInfos] = useState([]);
  const { t } = useTranslation("wallet");

  function getBalance() {
    axios
      .get(`/user/balance/history`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((res) => {
        setUserInfos(res.data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getBalance();
  }, []);

  const balance = userInfos
    .map((item) => item.amount)
    .reduce((prev, current) => prev + current, 0);

  return (
    <div>
      <p className="text-purple/60 text-lg font-serif2">{t("balanceTitle")}</p>
      <div className="border rounded-md border-purple/40 p-8 flex flex-col">
        <div className="border rounded-md flex justify-end w-[100%] p-2 text-2xl text-black border-purple/40">
          {balance} €
        </div>
        <div>
          <p className="text-purple/60 mt-8 mb-2 font-medium w-fit">
            {t("manageBalance")}
          </p>
          <div className="flex justify-between">
            <button className="btn w-[45%] bg-gray-dark text-white font-serif2 normal-case font-normal text-lg px-10">
              {t("addMoney")}
            </button>
            <button className="btn w-[45%] bg-gray-dark text-white font-serif2 normal-case font-normal text-lg px-10">
              {t("pullMoney")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
