import React, { useTransition } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import TitleProp from "../components/titleprop";

export default function Terms() {
  const { t } = useTranslation("terms");

  return (
    <div className="bg-white">
      <NavigationBar />
      <TitleProp directory={t("title")} />
      <div className="lg:mx-56 xl:mx-96 mx-16 mb-10">
        <div className="flex justify-between lg:my-20 my-10 items-center">
          <div className="bg-light-purple lg:px-5 px-3 py-2 text-purple rounded text-xs lg:text-lg">
            {t("version")}
          </div>
          <p className="font-serif2 text-even-lighter-gray text-2xs lg:text-base">
            {t("update")}
          </p>
        </div>
        <div className="font-serif2 space-y-4">
          <p className="lg:text-xl text-lg font-bold">{t("aboutTerms")}</p>
          <p className="text-purple lg:text-lg text-base pb-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            convallis massa in eleifend finibus. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Morbi convallis massa in eleifend
            finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Morbi convallis massa in eleifend finibus. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Morbi convallis massa in eleifend
            finibus.
          </p>
          <p className="text-purple lg:text-lg text-base pb-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            convallis massa in eleifend finibus. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Morbi convallis massa in eleifend
            finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Morbi convallis massa in eleifend finibus. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Morbi convallis massa in eleifend
            finibus.
          </p>
          <p className="text-purple lg:text-lg text-base pb-6">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            convallis massa in eleifend finibus. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Morbi convallis massa in eleifend
            finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Morbi convallis massa in eleifend finibus. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Morbi convallis massa in eleifend
            finibus.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}
