import React, { useState } from "react";
import AdminFooter from "../../components/admin/adminFooter";
import AdminNavigationBar from "../../components/admin/adminNavigationBar";
import AdminAddOeuvre from "../../components/admin/adminAddOeuvre";
import AdminOeuvreListComponent from "../../components/admin/adminOeuvreListComponent";
import { BiSearch } from "react-icons/bi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

export default function AdminOeuvre() {
  const [french, setFrench] = useState("");
  const [english, setEnglish] = useState("");
  const [chinese, setChinese] = useState("");
  const [baseArtction, setBaseArtction] = useState("");
  const [totalArtction, setTotalArtction] = useState("");

  return (
    <div>
      <AdminNavigationBar />
      <div className="mx-36 my-20 space-y-10 bg-white">
        <AdminAddOeuvre />
        <div>
          <p className="font-serif2 text-purple">Ajouter une oeuvre</p>
          <div className="border-purple/40 rounded-sm border">
            <div className="flex justify-between items-center m-4 mb-10">
              <div className="flex items-center border rounded-md w-fit border-light-purple">
                <BiSearch color="#d6d6d6" size={25} className="pl-2" />
                <input
                  placeholder="Rechercher..."
                  className="p-2 pr-20 placeholder-[#d6d6d6] rounded-md"
                />
              </div>
              <div className="flex items-center font-serif2 text-purple">
                <p className="text mr-4">Page 1 sur 23</p>
                <button>
                  <AiFillCaretLeft size={24} />
                </button>
                <button>
                  <AiFillCaretRight size={24} />
                </button>
              </div>
            </div>
            <div className="h-px w-full bg-light-purple" />
            <div className="space-y-6 mt-6">
              <AdminOeuvreListComponent />
              <AdminOeuvreListComponent />
              <AdminOeuvreListComponent />
              <AdminOeuvreListComponent />
              <AdminOeuvreListComponent />
              <AdminOeuvreListComponent />
              <AdminOeuvreListComponent />
              <AdminOeuvreListComponent />
            </div>
            <div className="flex items-center font-serif2 text-purple justify-end m-4">
              <p className="text mr-4">Page 1 sur 23</p>
              <button>
                <AiFillCaretLeft size={24} />
              </button>
              <button>
                <AiFillCaretRight size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <AdminFooter />
    </div>
  );
}
