import React, { useTransition } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import TitleProp from "../components/titleprop";

export default function Terms() {
  const { t } = useTranslation("privacyPolicy");

  return (
    <div className="bg-white">
      <NavigationBar />
      <TitleProp directory={t("title")} />
      <div className="lg:mx-56 xl:mx-96 mx-16 mb-10">
        <div className="flex justify-between lg:my-20 my-10 items-center">
          <div className="bg-light-purple lg:px-5 px-3 py-2 text-purple rounded text-xs lg:text-lg">
            {t("version")}
          </div>
          <p className="font-serif2 text-even-lighter-gray text-2xs lg:text-base">
            {t("update")}
          </p>
        </div>
        <div className="font-serif2 space-y-4">
          <p className="lg:text-xl text-lg font-bold">
            {t("aboutPrivacyPolicy")}
          </p>
          <p className="text-purple lg:text-lg text-base pb-6">
            <span className=" font-semibold text-xl">
              Informations sur la société :
            </span>{" "}
            <br />
            <span className="font-semibold">
              Site officiel de la société Art Market Stock Exchange :
              www.amse.com
            </span>{" "}
            <br />
            <br />
            <span className="font-semibold">L’entreprise :</span> <br />
            Forme juridique : Société à responsabilité limitée (Sarl) <br />
            Capital social : 5 000 Euros <br />
            SIRET : RCS PARIS 488 121 096 <br />
            TVA Intracommunautaire : FR 84488121096 <br />
            Gérant : David Folatre de Tibermont <br />
            Adresse du siège social : 252, boulevard Saint-Germain 75007 Paris{" "}
            <br />
            Courriel : contact@artibermont.com <br />
            Le site www.artibermont.com a été réalisé par la société Junior ISEP{" "}
            <br />
            <br />
            <span className=" font-semibold text-xl">
              Informations sur le site internet :{" "}
            </span>
            <br />
            <span className="font-semibold">Hébergement :</span> <br />
            Le site web est hébergé par : OVH <br />
            OVH SAS est une filiale de la société OVH Groupe SAS, SAS au capital
            de 10 069 020 euros, immatriculée au RCS de Lille Métropole sous le
            numéro 537 407 926 et dont le siège social est sis 2, rue
            Kellermann, 59100 Roubaix <br />
            www.ovh.com <br /> <br />
            <span className="font-semibold">
              Traitement des données personnelles :{" "}
            </span>{" "}
            <br />
            Conformément aux articles 34 et suivants de la loi n° 78-17 du 6
            janvier 1978 relative à l’Informatique, aux Fichiers et aux
            Libertés, toute personne peut obtenir communication, et le cas
            échéant, rectification ou suppression des informations la
            concernant, en s’adressant par voie postale au 252, boulevard
            Saint-Germain 7500 Paris. <br />
            <br />
            <span className="font-semibold">
              Droit applicable et lois concernées :
            </span>{" "}
            <br />
            Soumis au droit français, le site web www.artibermont.com est
            encadré par la loi n° 2004-2005 du 21 juin 2004 pour la confiance
            dans l’économie numérique, l’article L.335-2 du Code de la Propriété
            Intellectuelle et la loi « informatique et libertés » du 6 janvier
            1978 modifiée en 2004. <br /> <br />
            <span className="font-semibold">Données personnelles : </span>
            <br />
            Vous disposez d’un droit d’accès et de rectification portant sur vos
            données personnelles collectées sur ce site. Vous pouvez exercez ce
            droit par mail (contact@artibermont.com) ou par courrier :
            Tibermont, 252, boulevard Saint-Germain 75007 Paris. Les données
            collectées via les formulaires du site sont uniquement utilisées par
            Tibermont Antiquités et destinées à la communication d’informations.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}
