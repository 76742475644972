import React from "react";

export default function AdminWorkList() {
  return (
    <div>
      <div className="border-purple/40 rounded border font-serif2 p-5 px-8 space-y-5">
        <div className="flex justify-between items-center">
          <div className="flex space-x-10 items-center">
            <div className="bg-light-purple h-16 w-16" />
            <div className="font-serif2 flex space-x-6">
              <div>
                <p className="text-xs text-even-lighter-gray">
                  Montant initial de l'Artction
                </p>
                <p className="text-lg ">344,3€</p>
              </div>
            </div>

            <div className="font-serif2 flex space-x-6">
              <div className="h-10 w-px bg-even-lighter-gray/50" />
              <div>
                <p className="text-xs text-even-lighter-gray">
                  Nombre total d'Artction
                </p>
                <p className="text-lg ">344</p>
              </div>
            </div>

            <div className="font-serif2 flex space-x-6">
              <div className="h-10 w-px bg-even-lighter-gray/50" />
              <div>
                <p className="text-xs text-even-lighter-gray">
                  Montant initial de l'Artction
                </p>
                <p className="text-lg ">344,3€</p>
              </div>
            </div>

            <div className="font-serif2 flex space-x-6">
              <div className="h-10 w-px bg-even-lighter-gray/50" />
              <div>
                <p className="text-xs text-even-lighter-gray">
                  Nombre total d'Artction
                </p>
                <p className="text-lg ">344</p>
              </div>
            </div>
          </div>
          <div>
            <button className="bg-black py-2 px-10 mr-10 text-white font-serif2 rounded hover:bg-black/90">
              <p>Voir l'oeuvre</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
