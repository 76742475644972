import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";

export default function Categories() {
  const [hideCategories, setHideCategories] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [query, setQuery] = useState("");
  const { t } = useTranslation("filter");

  const categories = [
    `${t("19th Century European Art")}`,
    `${t("19th Century Furniture & Works of Art")}`,
    `${t("19th Century Sculpture")}`,
    `${t("African & Oceanic Art")}`,
    `${t("American Art")}`,
    `${t("American Folk Art")}`,
    `${t("American Furniture & Decorative Arts")}`,
    `${t("Ancient Art & Antiquities")}`,
    `${t("Arms & Armour")}`,
    `${t("Automobiles")}`,
    `${t("Books & Manuscripts")}`,
    `${t("British Drawings & Watercolours")}`,
    `${t("British Paintings")}`,
    `${t("Chinese Ceramics & Works of Art")}`,
    `${t("Chinese Paintings")}`,
    `${t("Clocks, Marine Chronometers & Barometers")}`,
    `${t("Coins & Medals")}`,
    `${t("Comic Strips & Illustrations")}`,
    `${t("Design")}`,
    `${t("Diamonds & Precious Stones")}`,
    `${t("Digital Art")}`,
    `${t("European Sculpture & Works of Art")}`,
    `${t("English Furniture & Works of Art")}`,
    `${t("European Ceramics & Glass")}`,
    `${t("European Furniture & Works of Art")}`,
    `${t("Gold Boxes & Objects of Vertu")}`,
    `${t("Handbags & Accessories")}`,
    `${t("Impressionist & Modern Art")}`,
    `${t("Indian, Himalayan & Southeast Asian Art")}`,
    `${t("Islamic Art")}`,
    `${t("Japanese Art")}`,
    `${t("Jewellery")}`,
    `${t("Korean Art")}`,
    `${t("Latin American Art")}`,
    `${t("Maritime Pictures")}`,
    `${t("Modern & Contemporary Art Asia Pacific")}`,
    `${t("Modern & Contemporary")}`,
    `${t("Modern British & Irish Art")}`,
    `${t("Musical Instruments")}`,
    `${t("NFTs")}`,
    `${t("Old Master Drawings")}`,
    `${t("Old Master Paintings")}`,
    `${t("Orientalist Art")}`,
    `${t("Photographs")}`,
    `${t("Portrait Miniatures")}`,
    `${t("Post-War & Contemporary Art")}`,
    `${t("Pre-Columbian Art")}`,
    `${t("Prints & Multiples")}`,
    `${t("Russian Art")}`,
    `${t("Russian Paintings")}`,
    `${t("Rugs & Carpets")}`,
    `${t("Science & Natural History")}`,
    `${t("Silver")}`,
    `${t("Sneakers, Streetwear & Collectibles")}`,
    `${t("South African Art")}`,
    `${t("South Asian Modern & Contemporary")}`,
    `${t("Sporting & Wildlife Art")}`,
    `${t("Sport Memorabilia")}`,
    `${t("Scottish Art")}`,
    `${t("Swiss Art")}`,
    `${t("Topographical Pictures")}`,
    `${t("Victorian, Pre-Raphaelite & British Impressionist Art")}`,
    `${t("Watches")}`,
    `${t("Wine & Spirits")}`,
  ];

  return (
    <div>
      {hideCategories ? (
        <div className="mb-2">
          <button
            onClick={() => {
              setHideCategories(false);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">Catégories</p>

            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div>
          <div className="">
            <button
              onClick={() => {
                setHideCategories(true);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">Catégories</p>

              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px mb-6">
            <div>
              <input
                type="text"
                placeholder={t("searchCategorie")}
                className="input input-bordered input-sm w-4/5 rounded-md max-w-xs bg-white"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            {seeMore
              ? categories
                  .filter((item) => {
                    if (query === "") {
                      return categories;
                    } else if (
                      item.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return categories;
                    }
                  })
                  .map((item) => (
                    <div className="my-2" key={item}>
                      <label className="flex flex-row justify-between items-center cursor-pointer ">
                        <div className="flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4 border-[#C7C7C7] checked:bg-purple checked"
                          />

                          <span className="label-text ml-2 text-[#444444]">
                            {item}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))
              : categories
                  .filter((item) => {
                    if (query === "") {
                      return categories;
                    } else if (
                      item.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return categories;
                    }
                  })
                  .slice(0, 5)
                  .map((item) => (
                    <div className="my-2" key={item}>
                      <label className="flex flex-row justify-between items-center cursor-pointer ">
                        <div className="flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4 border-[#C7C7C7] checked:bg-purple checked"
                          />

                          <span className="label-text ml-2 text-[#444444]">
                            {item}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}

            <div className="flex flex-col items-center text-[#C4c4c4]">
              <button
                className="flex items-center mb-2"
                onClick={() => setSeeMore(!seeMore)}
              >
                {!seeMore ? (
                  <div className="flex items-center">
                    <p>Voir plus</p>
                    <BiChevronDown />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <p>Voir moins</p>
                    <BiChevronUp />
                  </div>
                )}
              </button>
              <span className="h-px w-[80%] bg-[#C4c4c4]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
