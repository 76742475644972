import React from "react";

import navbar_fr from "./fr/navbar.json";
import footer_fr from "./fr/footer.json";
import propose_fr from "./fr/propose.json";
import signin_fr from "./fr/signin.json";
import signup_fr from "./fr/signup.json";
import forgotpassword_fr from "./fr/forgotpassword.json";
import terms_fr from "./fr/terms.json";
import privacypolicy_fr from "./fr/privacypolicy.json";
import help_fr from "./fr/help.json";
import contact_fr from "./fr/contact.json";
import wallet_fr from "./fr/wallet.json";
import myaccount_fr from "./fr/myaccount.json";
import market_fr from "./fr/market.json";
import work_fr from "./fr/work.json";
import filter_fr from "./fr/filter.json";

import navbar_en from "./en/navbar.json";
import footer_en from "./en/footer.json";
import propose_en from "./en/propose.json";
import signin_en from "./en/signin.json";
import signup_en from "./en/signup.json";
import forgotpassword_en from "./en/forgotpassword.json";
import terms_en from "./en/terms.json";
import privacypolicy_en from "./en/privacypolicy.json";
import help_en from "./en/help.json";
import contact_en from "./en/contact.json";
import wallet_en from "./en/wallet.json";
import myaccount_en from "./en/myaccount.json";
import market_en from "./en/market.json";
import work_en from "./en/work.json";
import filter_en from "./en/filter.json";

import navbar_ch from "./ch/navbar.json";
import footer_ch from "./ch/footer.json";
import propose_ch from "./ch/propose.json";
import signin_ch from "./ch/signin.json";
import signup_ch from "./ch/signup.json";
import forgotpassword_ch from "./ch/forgotpassword.json";
import terms_ch from "./ch/terms.json";
import privacypolicy_ch from "./ch/privacypolicy.json";
import help_ch from "./ch/help.json";
import contact_ch from "./ch/contact.json";
import wallet_ch from "./ch/wallet.json";
import myaccount_ch from "./ch/myaccount.json";
import market_ch from "./ch/market.json";
import work_ch from "./ch/work.json";
import filter_ch from "./ch/filter.json";

export const fr = {
  navbar: navbar_fr,
  footer: footer_fr,
  propose: propose_fr,
  signin: signin_fr,
  signup: signup_fr,
  forgotpassword: forgotpassword_fr,
  terms: terms_fr,
  privacyPolicy: privacypolicy_fr,
  help: help_fr,
  contact: contact_fr,
  wallet: wallet_fr,
  myAccount: myaccount_fr,
  market: market_fr,
  work: work_fr,
  filter: filter_fr,
};
export const en = {
  navbar: navbar_en,
  footer: footer_en,
  propose: propose_en,
  signin: signin_en,
  signup: signup_en,
  forgotpassword: forgotpassword_en,
  terms: terms_en,
  privacyPolicy: privacypolicy_en,
  help: help_en,
  contact: contact_en,
  wallet: wallet_en,
  myAccount: myaccount_en,
  market: market_en,
  work: work_en,
  filter: filter_en,
};
export const ch = {
  navbar: navbar_ch,
  footer: footer_ch,
  propose: propose_ch,
  signin: signin_ch,
  signup: signup_ch,
  forgotpassword: forgotpassword_ch,
  terms: terms_ch,
  privacyPolicy: privacypolicy_ch,
  help: help_ch,
  contact: contact_ch,
  wallet: wallet_ch,
  myAccount: myaccount_ch,
  market: market_ch,
  work: work_ch,
  filter: filter_ch,
};

const translations = { fr, en, ch };
export default translations;
