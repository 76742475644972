import React from "react";
import { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

export default function AdminOeuvreListComponent() {
  const [isOpened, setIsOpened] = useState(false);

  if (!isOpened) {
    return (
      <div className="pr-20 border-b border-light-purple px-5">
        <div className="flex justify-between">
          <div className="bg-light-purple h-16 w-16" />

          <div className="font-serif2 ">
            <p className="text-xs text-even-lighter-gray">Titre en français</p>
            <p className="text-lg ">Lorem ipsum</p>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Montant initial de l'Artction
              </p>
              <p className="text-lg ">344,3€</p>
            </div>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Nombre total d'Artction
              </p>
              <p className="text-lg ">344</p>
            </div>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Nombre de transactions
              </p>
              <p className="text-lg ">2938</p>
            </div>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Montant actuel de l'Artction
              </p>
              <p className="text-lg ">2938 €</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-2">
          <button
            className="flex items-center font-serif2 text-even-lighter-gray"
            onClick={() => setIsOpened(true)}
          >
            Voir plus
            <HiChevronDown />
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pr-20 border-b border-light-purple px-5">
        <div className="flex justify-between">
          <div className="bg-light-purple h-16 w-16" />

          <div className="font-serif2 ">
            <p className="text-xs text-even-lighter-gray">Titre en français</p>
            <p className="text-lg ">Lorem ipsum</p>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Montant initial de l'Artction
              </p>
              <p className="text-lg ">344,3€</p>
            </div>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Nombre total d'Artction
              </p>
              <p className="text-lg ">344</p>
            </div>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Nombre de transactions
              </p>
              <p className="text-lg ">2938</p>
            </div>
          </div>

          <div className="font-serif2 flex space-x-6">
            <div className="h-10 w-px bg-even-lighter-gray/50" />
            <div>
              <p className="text-xs text-even-lighter-gray">
                Montant actuel de l'Artction
              </p>
              <p className="text-lg ">2938 €</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mx-16 items-center space-x-32 font-serif2">
          <div>
            <p className="text-xs text-even-lighter-gray">
              Liste des ordres en cours
            </p>
            <div className="flex space-x-20 m-2">
              <p>Lorem Ipsum</p>
              <p>Executé</p>
              <p>Lorem Ipsum</p>
            </div>
            <div className="w-full h-px bg-even-lighter-gray/50" />
            <div className="flex space-x-20 m-2">
              <p>Lorem Ipsum</p>
              <p>Executé</p>
              <p>Lorem Ipsum</p>
            </div>
            <div className="w-full h-px bg-even-lighter-gray/50" />
            <div className="flex space-x-20 m-2">
              <p>Lorem Ipsum</p>
              <p>Executé</p>
              <p>Lorem Ipsum</p>
            </div>
            <div className="w-full h-px bg-even-lighter-gray/50" />
          </div>
          <div className="h-48 w-px bg-even-lighter-gray/50" />
          <div>
            <p className="text-xs text-even-lighter-gray">
              Liste des dernières transactions
            </p>
            <div className="flex space-x-20 m-2">
              <p>Lorem Ipsum</p>
              <p>Lorem Ipsum</p>
              <p>Lorem Ipsum</p>
            </div>
            <div className="w-full h-px bg-even-lighter-gray/50" />
            <div className="flex space-x-20 m-2">
              <p>Lorem Ipsum</p>
              <p>Lorem Ipsum</p>
              <p>Lorem Ipsum</p>
            </div>
            <div className="w-full h-px bg-even-lighter-gray/50" />
            <div className="flex space-x-20 m-2">
              <p>Lorem Ipsum</p>
              <p>Lorem Ipsum</p>
              <p>Lorem Ipsum</p>
            </div>
            <div className="w-full h-px bg-even-lighter-gray/50" />
          </div>
        </div>
        <div className="space-x-10 mx-10 mb-5">
          <button className="btn bg-red text-white hover:bg-dark-red hover:border-dark-red font-serif2 normal-case font-normal text-md px-12 mt-4">
            Geler les ordres
          </button>
          <button className="btn bg-gray-dark text-white font-serif2 normal-case font-normal text-md px-12 mt-4">
            Retirer l'oeuvre pour une vente
          </button>
        </div>
        <div className="flex justify-center my-2">
          <button
            className="flex items-center font-serif2 text-even-lighter-gray"
            onClick={() => setIsOpened(false)}
          >
            Voir moins
            <HiChevronUp />
          </button>
        </div>
      </div>
    );
  }
}
