import React from "react";

export default function AdminStat({ data }) {
  return (
    <div className="w-fit">
      <div className="border-purple/40 rounded border p-2">
        <p className="text-even-lighter-gray">{data.title}</p>
        <div className="flex justify-between items-end mt-2">
          <p className="text-xl">{data.value}</p>
          {/* <p className=" text-green">+ 2,3%</p> */}
        </div>
      </div>
    </div>
  );
}
