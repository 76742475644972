import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { HiChevronDown } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import AdminFooter from "./adminFooter";
import AdminWorkList from "./adminWorkList";

export default function AdminUser() {
  const navigate = useNavigate();
  const [isFrozen, setIsFrozen] = useState(false);

  const { state } = useLocation();
  const data = state;

  return (
    <div>
      <div className="navbar flex justify-between bg-gray-dark text-white font-serif ">
        <button
          className="text-xl ml-10"
          onClick={() => navigate("/adminusers", { state: data })}
        >
          <BiChevronLeft />
          Utilisateurs
        </button>
      </div>
      <div className="w-full bg-purple h-4" />
      <div className="mx-36 my-10">
        {/* User Data */}
        <p className="font-serif2 text-purple">Données</p>
        <div className="border-purple/40 rounded border font-serif2 p-5 px-8 space-y-5 mb-7">
          <div className="flex justify-between items-center">
            <div>
              <p className="text-xs text-even-lighter-gray">Nom</p>
              <p className="text-lg text-purple">{data.lastName}</p>
            </div>
            <div>
              <p className="text-xs text-even-lighter-gray">Prénom</p>
              <p className="text-lg text-purple">{data.firstName}</p>
            </div>
            <div>
              <p className="text-xs text-even-lighter-gray">Adresse email</p>
              <p className="text-lg text-purple">{data.email}</p>
            </div>
            <div>
              <p className="text-xs text-even-lighter-gray">
                Numéro de téléphone
              </p>
              <p className="text-lg text-purple">{data.phone}</p>
            </div>
            <div>
              <p className="text-xs text-even-lighter-gray">Solde</p>
              <p className="text-lg text-purple">{data.balance} €</p>
            </div>
            <div>
              <p className="text-xs text-even-lighter-gray">
                Numbre d'Artctions détenues
              </p>
              <p className="text-lg text-purple">{data.nb_artction}</p>
            </div>
          </div>
          <div className="flex justify-between">
            {isFrozen ? (
              <button
                className="bg-red py-2 px-5 text-white font-serif2 rounded hover:bg-red/70"
                onClick={() => setIsFrozen(false)}
              >
                <p>Geler le compte</p>
              </button>
            ) : (
              <button
                className="bg-green py-2 px-5 text-white font-serif2 rounded hover:bg-green/70"
                onClick={() => setIsFrozen(true)}
              >
                <p>Dégeler le compte</p>
              </button>
            )}
            <button className="bg-red py-2 px-8 text-white font-serif2 rounded hover:bg-red/70">
              <p>Supprimer le compte</p>
            </button>
          </div>
        </div>

        {/* Notification User Ask to Get Money Out */}
        <div className="border-purple/40 rounded border font-serif2 p-3 px-8  mb-10 flex justify-between items-center">
          <p className=" text-light-gray">
            M.DOE a demandé une augmentation de X € de son solde.
          </p>
          <div className="space-x-10">
            <button className="bg-green py-2 px-10 text-white font-serif2 rounded hover:bg-green/70">
              <p>Accepter</p>
            </button>
            <button className="bg-red py-2 px-10 text-white font-serif2 rounded hover:bg-red/70">
              <p>Refuser</p>
            </button>
          </div>
        </div>

        {/* User Actions */}
        <div className="mb-10">
          <p className="font-serif2 text-purple mb-px">
            Liste des mouvements effectués sur le compte
          </p>
          <div className="space-y-3">
            <div className="border-purple/40 rounded border font-serif2 p-2 pl-5">
              <p className=" text-light-gray">
                M.DOE a retiré X € de son solde vers son compte bancaire.
              </p>
            </div>
            <div className="border-purple/40 rounded border font-serif2 p-2 pl-5">
              <p className=" text-light-gray">
                M.DOE a vendu X Artctions pour Y €
              </p>
            </div>
            <div className="border-purple/40 rounded border font-serif2 p-2 pl-5">
              <p className=" text-light-gray">
                M.DOE a retiré X € de son solde vers son compte bancaire.
              </p>
            </div>
          </div>
          <div className="flex justify-center my-2">
            <button className="flex items-center font-serif2 text-even-lighter-gray">
              Voir plus
              <HiChevronDown />
            </button>
          </div>
          <div className="w-full h-px bg-even-lighter-gray/50" />
        </div>

        {/* Work List */}
        <div>
          <p className="font-serif2 text-purple">Liste des oeuvres</p>
          <div className="space-y-3">
            <AdminWorkList />
            <AdminWorkList />
            <AdminWorkList />
            <AdminWorkList />
            <AdminWorkList />
          </div>
          <div className="flex justify-center my-2">
            <button className="flex items-center font-serif2 text-even-lighter-gray">
              Voir plus
              <HiChevronDown />
            </button>
          </div>
          <div className="w-full h-px bg-even-lighter-gray/50" />
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
