import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";

export default function Style() {
  const [hideStyle, setHideStyle] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [query, setQuery] = useState("");

  const { t } = useTranslation("filter");
  const styles = [
    `${t("contemporary")}`,
    `${t("abstract")}`,
    `${t("modern")}`,
    `${t("impressionism")}`,
    `${t("popArt")}`,
    `${t("surrealism")}`,
    `${t("expressionism")}`,
    `${t("postImpressionism")}`,
    `${t("urbanArt")}`,
    `${t("oldMasters")}`,
    `${t("photorealism")}`,
    `${t("minimalistic")}`,
    `${t("decoArt")}`,
    `${t("afterWar")}`,
  ];

  return (
    <div>
      {hideStyle ? (
        <div className="mb-2">
          <button
            onClick={() => {
              setHideStyle(false);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">{t("style")}</p>

            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div>
          <div className="">
            <button
              onClick={() => {
                setHideStyle(true);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">{t("style")}</p>

              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px mb-6 space-y-2">
            <div>
              <input
                type="text"
                placeholder={t("searchStyle")}
                className="input input-bordered input-sm w-4/5 rounded-md max-w-xs bg-white"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>

            {seeMore
              ? styles
                  .filter((item) => {
                    if (query === "") {
                      return styles;
                    } else if (
                      item.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return styles;
                    }
                  })
                  .map((item) => (
                    <div className="mb-2" key={item}>
                      <label className="flex flex-row justify-between items-center cursor-pointer ">
                        <div className="flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4 border-[#C7C7C7] checked:bg-purple"
                          />

                          <span className="label-text ml-2 text-[#444444]">
                            {item}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))
              : styles
                  .filter((item) => {
                    if (query === "") {
                      return styles;
                    } else if (
                      item.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return styles;
                    }
                  })
                  .slice(0, 5)
                  .map((item) => (
                    <div className="mb-2" key={item}>
                      <label className="flex flex-row justify-between items-center cursor-pointer ">
                        <div className="flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4 border-[#C7C7C7] checked:bg-purple"
                          />

                          <span className="label-text ml-2 text-[#444444]">
                            {item}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}

            <div className="flex flex-col items-center text-[#C4c4c4]">
              <button
                className="flex items-center mb-2"
                onClick={() => setSeeMore(!seeMore)}
              >
                {!seeMore ? (
                  <div className="flex items-center">
                    {t("seeMore")}
                    <BiChevronDown />
                  </div>
                ) : (
                  <div className="flex items-center">
                    {t("seeLess")}
                    <BiChevronUp />
                  </div>
                )}
              </button>
              <span className="h-px w-[80%] bg-[#C4c4c4]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
