import { useState } from "react";
import { createContext } from "react";

const FilterContext = createContext({});

export function FilterProvider({ children }) {
  const defaultState = {
    artctionType: "allTypes",
    basicFilters: [],
    workPrice: ["allPrices"],
    artctionPrice: ["allPrices"],
    artist: [],
    categories: [],
    style: [],
  };

  const [filter, setFilter] = useState(defaultState);

  return (
    <FilterContext.Provider value={{ filter, setFilter, defaultState }}>
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContext;
