import "./artobject.css";

import React from "react";

import { useNavigate } from "react-router-dom";
import { FiTrendingDown, FiTrendingUp } from "react-icons/fi";
import { useTranslation } from "react-i18next";

export default function ArtObject({
  workId,
  image,
  title,
  isIntroductory,
  avgPrice,
  nbAvailable,
  evolution,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("market");

  return (
    <div>
      <div className="object">
        <div className=" bg-black w-[364px] h-[46px] relative top-[230px] rounded-b flex flex-row justify-between items-center">
          <div className="flex flex-col ml-2.5">
            <p className="text-md font-serif2 text-white">{title}</p>
            {isIntroductory ? (
              <p className="text-xs font-serif2 text-[#bbbbbb]">
                {t("secondaryMarket")}
              </p>
            ) : (
              <p className="text-xs font-serif2 text-[#bbbbbb]">
                {t("primaryMarket")}
              </p>
            )}
          </div>
          <div className="mr-2.5">
            <button
              className="btn bg-white text-black border-none font-serif2 normal-case font-normal btn-sm hover:bg-[#EFEFEF]"
              onClick={() => navigate("/oeuvre", { state: workId })}
            >
              {t("moreInfo")}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between my-1 mx-[7px]">
        <div className="flex flex-col font-serif2">
          <p className="text-even-lighter-gray text-[14px]">
            {t("artctionPrice")}
          </p>
          <p className="text-xl text-black -mt-1">{avgPrice} €</p>
          <p className="text-xs text-even-lighter-gray mt-1">
            {nbAvailable} {t("artctionsRemaining")}
          </p>
        </div>
        <div className="flex flex-col items-end font-serif2">
          <p className="text-even-lighter-gray text-[14px]">{t("var1Week")}</p>
          {evolution >= 0 ? (
            <div className="flex items-center space-x-1 text-green text-lg">
              <FiTrendingUp />
              <p>{evolution} %</p>
            </div>
          ) : (
            <div className="flex items-center space-x-1 text-red text-lg">
              <FiTrendingDown />
              <p>{evolution} %</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
