import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import useFilter from "../../hooks/useFilter";

export default function ArtctionType() {
  const [hideArtctionType, setHideArtctionType] = useState(false);
  const [selectedOption, setSelectedOption] = useState("allTypes");

  const { filter, setFilter } = useFilter();

  function handleOptionChange(e) {
    setSelectedOption(e.target.value || filter.artctionType);
    setFilter((prevFilter) => ({
      ...prevFilter,
      artctionType: e.target.value,
    }));
    console.log(e.target.value);
    return e.target.value;
  }

  return (
    <div>
      {hideArtctionType ? (
        <div className="mb-2">
          <button
            onClick={() => {
              setHideArtctionType(false);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">Type d'Artction</p>

            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div>
          <div>
            <button
              onClick={() => {
                setHideArtctionType(true);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">Type d'Artction</p>

              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px mb-6">
            <div className="my-2">
              <label className="flex flex-row justify-between items-center cursor-pointer ">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="artctionType"
                    onChange={handleOptionChange}
                    checked={selectedOption === "allTypes"}
                    value="allTypes"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none"
                  />

                  <span className="label-text ml-2 text-[#444444]">
                    Tout type d'artctions
                  </span>
                </div>
              </label>
            </div>
            <div className="mb-2">
              <label className="flex flex-row justify-between items-center cursor-pointer ">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="artctionType"
                    onChange={handleOptionChange}
                    checked={selectedOption === "ordinaryArtction"}
                    value="ordinaryArtction"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none checked"
                  />

                  <span className="label-text ml-2 text-[#444444]">
                    Arction Ordinaire
                  </span>
                </div>
              </label>
            </div>
            <div className="mb-2">
              <label className="flex flex-row justify-between items-center cursor-pointer ">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="artctionType"
                    onChange={handleOptionChange}
                    checked={selectedOption === "introductoryArtction"}
                    value="introductoryArtction"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none checked"
                  />

                  <span className="label-text ml-2 text-[#444444]">
                    Artction d'introduction
                  </span>
                </div>
              </label>
            </div>
            <div className="mb-2">
              <label className="flex flex-row justify-between items-center cursor-pointer ">
                <div className="flex flex-row items-center">
                  <input
                    type="radio"
                    name="artctionType"
                    onChange={handleOptionChange}
                    checked={selectedOption === "priorityArtction"}
                    value="priorityArtction"
                    className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple rounded-none checked"
                  />

                  <span className="label-text ml-2 text-[#444444]">
                    Artction Prioritaire
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
