import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import TitleProp from "../components/titleprop";

export default function Help() {
  const [query, setQuery] = useState("");
  const { t } = useTranslation("help");
  const questionList = [
    `${t("1")}`,
    `${t("2")}`,
    `${t("3")}`,
    `${t("4")}`,
    `${t("5")}`,
    `${t("6")}`,
    `${t("7")}`,
    `${t("8")}`,
    `${t("9")}`,
    `${t("10")}`,
    `${t("11")}`,
    `${t("12")}`,
    `${t("13")}`,
  ];

  return (
    <div className="bg-white">
      <NavigationBar />
      <TitleProp directory={t("help")} />
      <div className="lg:mx-72 xl:mx-96 mx-16">
        <div className="flex items-center my-10 border border-even-lighter-gray/70 w-fit rounded ">
          <BiSearch color="#d6d6d6" size={25} className="pl-2" />
          <input
            placeholder={t("search")}
            className="p-2 lg:pr-56  placeholder-[#d6d6d6] rounded"
            onChange={(e) => setQuery(e.target.value)}
            size="35"
          />
        </div>
        {questionList
          .filter((item) => {
            if (query === "") {
              return questionList;
            } else if (item.toLowerCase().includes(query.toLowerCase())) {
              return questionList;
            }
          })
          .map((item, index) => (
            <div className="w-80% font-serif2 my-10" key={index}>
              <p className="text-2xl text-black font-medium mb-4">{item}</p>
              <p className="text-md lg:text-lg text-purple">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                convallis massa in eleifend finibus. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Morbi convallis massa in eleifend
                finibus.
              </p>
              <div className="h-px w-full my-14 bg-even-lighter-gray/20 " />
            </div>
          ))}
      </div>
      <Footer />
    </div>
  );
}
