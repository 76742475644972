import React from "react";

export default function TitleProp({ directory }) {
  return (
    <div>
      <div className="flex xl:mx-72 mx-16 flex-col lg:flex-row lg:justify-between space-y-10 lg:space-y-0 justify-center items-center lg:text-5xl text-4xl font-serif h-72">
        <p className="text-center lg:text-left">Art Market Stock Exchange</p>
        <p>{directory}</p>
      </div>
      <div className="w-full h-px bg-even-lighter-gray/40" />
    </div>
  );
}
