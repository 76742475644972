import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation("footer");

  return (
    <footer className="z-20">
      <div className="bg-dark-black text-white py-8 lg:py-5">
        <div className="flex flex-col space-y-10 text-center font-serif2 lg:flex-row lg:items-center lg:justify-center lg:space-y-0 lg:space-x-24 2xl:space-x-48">
          <div className="flex flex-col space-y-2 lg:flex-row lg:space-y-0 lg:space-x-10 2xl:space-x-24">
            <Link to="/disclosures" className="link link-hover">
              {t("privacypolicy")}
            </Link>
            <Link to="/terms" className="link link-hover">
              {t("terms")}
            </Link>
            <Link to="/help" className="link link-hover">
              {t("help")}
            </Link>
            <Link to="/contact" className="link link-hover">
              {t("contact")}
            </Link>
          </div>
          <a
            href="https://juniorisep.com/accueil-new/"
            className="link link-hover"
          >
            {t("juniorisep")}
          </a>
        </div>
      </div>
    </footer>
  );
}
