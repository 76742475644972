import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import axios from "../api/axios";
import moment from "moment/moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";

const LANG = localStorage.getItem("lng") || "fr";
let language = "fr";

if (LANG === "fr") {
  language = "fr";
} else if (LANG === "en") {
  language = "en-gb";
} else if (LANG === "ch") {
  language = "zn-cn";
}

export default function Chart({ workId }) {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    async function getEvolutionData(workId) {
      try {
        const response = await axios.get(`/work/evolution?work_id=${workId}`);
        const data = Object.values(response?.data);
        console.log(data);
        const transformedData = data.map((d) => [
          moment(d.changed_at).valueOf(),
          d.average_price,
        ]);
        setGraphData(transformedData);

        console.log(graphData);
      } catch (e) {
        console.log(e);
      }
    }

    getEvolutionData(workId);
  }, [workId]);

  const options = {
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      buttons: [
        {
          type: "day",
          count: 1,
          text: "1J",
        },
        {
          type: "day",
          count: 7,
          text: "1S",
        },
        {
          type: "month",
          count: 1,
          text: "1M",
        },
        {
          type: "month",
          count: 3,
          text: "3M",
        },
        { type: "year", count: 1, text: "1A" },
      ],
      selected: 3,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        return (
          this.y +
          "€" +
          "</b><br/>" +
          moment(this.x).locale(language).format("LLL")
        );
      },
    },
    xAxis: {
      type: "datetime",
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Prix",
        type: "spline",
        color: "#484475",
        data: graphData,

        tooltip: {
          valueDecimals: 2,
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={options}
      />
    </div>
  );
}
