import React, { useState, useEffect } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import axios from "../api/axios";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function ArtctionOwnedComp({ data }) {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [query, setQuery] = useState("");
  const [seeMore, setSeeMore] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation("wallet");

  return (
    <div className="rounded-lg py-3 px-5 flex flex-col gap-4 font-serif2 border border-light-purple my-2 bg-white">
      <div className="flex justify-between items-start">
        <div className="text-purple">
          <button
            className="font-bold text-lg"
            onClick={() => navigate("/oeuvre", { state: data.work_id })}
          >
            {data.title}
          </button>
        </div>
        <div className="text-right">
          <p className="text-xs text-even-lighter-gray/60">Var. depuis achat</p>
          {data.abs_variation >= 0 ? (
            <p className="text-green font-semibold text-lg -mt-1">
              +{data.abs_variation} %
            </p>
          ) : (
            <p className="text-red font-semibold text-lg -mt-1">
              {data.abs_variation} %
            </p>
          )}
        </div>
      </div>
      <div className="flex gap-14 text-xs">
        <div>
          <p className=" text-even-lighter-gray/60">
            Nombre <br /> d'Artctions
          </p>
          <p className="text-purple text-lg font-medium">{data.nb_artctions}</p>
        </div>
        <div className="flex gap-5">
          <div className="h-full w-px bg-even-lighter-gray/20"></div>
          <div>
            <p className=" text-even-lighter-gray/60">
              Prix actuel <br /> de l'Artction
            </p>
            <p className="text-purple text-lg font-medium">
              {data.avg_price} €
            </p>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="h-full w-px bg-even-lighter-gray/20"></div>
          <div>
            <p className=" text-even-lighter-gray/60">
              Montant <br /> Total
            </p>
            <p className="text-purple text-lg font-medium">
              {data.total_price} €
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
