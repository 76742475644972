import React, { useState, useEffect } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import axios from "../api/axios";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import TransactionHistoryComp from "./TransactionHistoryComp";
import OrderHistoryComp from "./OrderHistoryComp";
import ArtctionOwnedComp from "./ArtctionOwnedComp";
import { useNavigate } from "react-router-dom";

export default function TransactionHistoryMobile() {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [query, setQuery] = useState("");
  const [seeMore, setSeeMore] = useState(false);

  const { t } = useTranslation("wallet");

  const navigate = useNavigate();

  function getTransactionHistory() {
    axios
      .get(`/user/balance/history`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((res) => {
        setTransactionHistory(res.data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getTransactionHistory();
  }, []);

  return (
    <div>
      <p className="text-purple/60 font-serif2 lg:text-lg text-base">
        {t("transactionHistoryTitle")}
      </p>
      <div>
        <div className="flex items-center border rounded-md bg-white border-light-purple w-fit">
          <BiSearch color="#d6d6d6" size={25} className="pl-2" />
          <input
            placeholder={t("searchBar")}
            className="p-2 placeholder-[#d6d6d6] rounded-md"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        {transactionHistory.length === 0 ? (
          <div className="flex flex-col justify-center items-center my-2 h-96 bg-white border rounded-lg border-light-purple">
            <p className="font-serif md:text-4xl text-3xl font-medium text-purple/80 text-center">
              Vous n'avez pas de transactions enregistrées
            </p>
            <p className="font-serif2 text-purple/60 text-center">
              Veuillez créditer votre compte, puis rendez-vous sur la page
              <span
                className="text-purple cursor-pointer"
                onClick={() => {
                  navigate("/market");
                }}
              >
                {" "}
                marchés{" "}
              </span>
              pour placer des ordres
            </p>
          </div>
        ) : seeMore ? (
          transactionHistory
            .filter((item) => {
              if (query === "") {
                return transactionHistory;
              } else if (
                item.source.toLowerCase().includes(query.toLowerCase())
              ) {
                return transactionHistory;
              }
            })
            .map((item, index) => (
              <TransactionHistoryComp key={index} data={item} />
            ))
        ) : (
          transactionHistory
            .filter((item) => {
              if (query === "") {
                return transactionHistory;
              } else if (
                item.source.toLowerCase().includes(query.toLowerCase())
              ) {
                return transactionHistory;
              }
            })
            .slice(0, 10)
            .map((item, index) => (
              <TransactionHistoryComp key={index} data={item} />
            ))
        )}
      </div>
      <div className="flex justify-center my-2 text-purple/50 font-serif2">
        <button
          className="flex items-center "
          onClick={() => setSeeMore(!seeMore)}
        >
          {transactionHistory.length === 0 ? (
            <div></div>
          ) : !seeMore ? (
            <div className="flex items-center">
              {t("seeMore")}
              <BiChevronDown />
            </div>
          ) : (
            <div className="flex items-center">
              {t("seeLess")}
              <BiChevronUp />
            </div>
          )}
        </button>
      </div>
    </div>
  );
}
