import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./carousel.css";

import { Pagination, Navigation } from "swiper";

export default function Carousel() {
  return (
    <div className="mb-20 mx-72">
      <Swiper
        slidesPerView={3}
        slidesPerGroup={1}
        loop={true}
        // loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div>
            <div className="h-96 w-80 bg-dark-black rounded text-center text-white">
              image
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="h-96 w-80 bg-dark-black rounded text-center text-white">
              image
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="h-96 w-80 bg-dark-black rounded text-center text-white">
              image
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="h-96 w-80 bg-dark-black rounded text-center text-white">
              image
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <div className="h-96 w-80 bg-dark-black rounded text-center text-white">
              image
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
