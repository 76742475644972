import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function AdminNavigationBar() {
  const { logout } = useAuth();

  const onLogoutPressed = () => {
    logout();
  };

  return (
    <div>
      <div className="navbar flex justify-between bg-gray-dark text-white font-serif ">
        <div className="flex flex-col ml-36">
          <p className="2xl:text-4xl xl:text-3xl lg:text-2xl">ASME</p>
          <p className="bg-white text-black px-2 rounded-full">
            Administrateur
          </p>
        </div>
        <div className="flex 2xl:text-lg xl:text-base lg:text-sm">
          <ul className="flex row space-x-16 items-center">
            <li>
              <Link
                to="/adminhome"
                className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
              >
                Accueil
              </Link>
            </li>
            <li>
              <Link
                className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
                to="/adminoeuvre"
              >
                Oeuvre
              </Link>
            </li>
            <li>
              <Link
                className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
                to="/adminusers"
              >
                Utilisateurs
              </Link>
            </li>
            <li>
              <Link
                className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
                to="/adminallorders"
              >
                Carnet d'ordres
              </Link>
            </li>
          </ul>
        </div>
        <Link
          to="/"
          className="text-black bg-white rounded-md p-2 text-md font-semibold mr-36"
          onClick={onLogoutPressed}
        >
          Se Déconnecter
        </Link>
      </div>
      <div className="w-full bg-purple h-4" />
    </div>
  );
}
