import React, { useState } from "react";
import { BiChevronDown, BiChevronUp, BiChevronRight } from "react-icons/bi";

const MAX_WORK_PRICE = 5000;

export default function PriceFilter() {
  const [hideWorkPriceFilters, setHideWorkPriceFilters] = useState(false);

  const [minWorkPrice, setMinWorkPrice] = useState(0);
  const [maxWorkPrice, setMaxWorkPrice] = useState(MAX_WORK_PRICE);
  return (
    <div>
      {/* Hide Work */}
      {hideWorkPriceFilters ? (
        <div className="mb-2">
          <button
            onClick={() => {
              setHideWorkPriceFilters(false);
            }}
            className="flex justify-between w-full items-center"
          >
            <p className="text-purple font-serif text-2xl">Prix de l'oeuvre</p>

            <BiChevronUp size={25} color={"#545180"} />
          </button>
        </div>
      ) : (
        <div>
          <div className="">
            <button
              onClick={() => {
                setHideWorkPriceFilters(true);
              }}
              className="flex justify-between w-full items-center"
            >
              <p className="text-purple font-serif text-2xl">
                Prix de l'oeuvre
              </p>

              <BiChevronDown size={25} color={"#545180"} />
            </button>
          </div>
          <div className="font-serif2 mt-px mb-6">
            <div className=" mb-2 ">
              <label className="flex flex-row items-center cursor-pointer ">
                <input
                  type="radio"
                  name="radio-2"
                  className="radio w-4 h-4 border-[#C7C7C7]  checked:bg-purple  checked"
                />

                <span className="label-text ml-2 text-[#444444]">
                  Tous les prix
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-2"
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444] ">
                  Moins de 1000€
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-2"
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444]">
                  Moins de 2000€
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-2"
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444]">
                  Moins de 3000€
                </span>
              </label>
            </div>
            <div className=" mb-2">
              <label className="flex flex-row items-center  cursor-pointer">
                <input
                  type="radio"
                  name="radio-2"
                  className="radio w-4 h-4 border-[#C7C7C7] checked:bg-purple "
                />
                <span className="label-text ml-2 text-[#444444]">
                  4000€ et plus
                </span>
              </label>
            </div>
            <div className="flex flex-row items-center mb-2 mt-8 space-x-4">
              <input
                type="text"
                placeholder="EUR min."
                className="input input-bordered w-20 text-2xs max-w-xs mr-4 border-[#C7C7C7] bg-white"
                onChange={(e) => setMinWorkPrice(e.target.value)}
              />
              à
              <input
                type="text"
                placeholder="EUR max."
                className="input input-bordered w-20 text-2xs max-w-xs border-[#C7C7C7] bg-white"
                onChange={(e) => setMaxWorkPrice(e.target.value)}
              />
              <button className=" border-even-lighter-gray">
                <BiChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
