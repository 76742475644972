import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ArtctionPrice from "./artctionprice";
import ArtctionType from "./artctiontype";
import ArtistFilter from "./artistfilter";
import ArtPeriod from "./artperiod";
import BasicFilters from "./basicfilters";
import Categories from "./categories";
import PriceFilter from "./pricefilter";
import Style from "./style";
import useFilter from "../../hooks/useFilter";

export default function Filter() {
  const { setFilter, defaultState } = useFilter();

  function handleEraseFilters() {
    setFilter(defaultState);
  }

  const { t } = useTranslation("filter");
  return (
    <div className="mt-7 space-y-4 mr-20 mb-20">
      <button
        onClick={handleEraseFilters}
        className="btn w-56 bg-white text-gray-dark font-serif2 normal-case font-normal text-lg hover:bg-[#f8f8f8]"
      >
        {t("eraseFilters")}
      </button>
      <div className="w-72">
        {/* Hide Artction Type */}
        <ArtctionType />

        {/* Hide Basic Filters */}
        <BasicFilters />

        {/* Hide Work */}
        <PriceFilter />

        {/* Hide Arction Price */}
        <ArtctionPrice />

        {/* Hide Artist Price */}
        <ArtistFilter />

        {/* Hide Categories */}
        <Categories />

        {/* Hide Art Period */}
        <ArtPeriod />

        {/* Hide Style */}
        <Style />
      </div>
    </div>
  );
}
