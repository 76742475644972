import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";

export default function ConfirmRegister() {
  const { t } = useTranslation("contact");
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    console.log(token);
    const res = axios.post(`/confirmRegister`, {
      params: {
        token: token,
      },
    });
    try {
      console.log(res);
      setStatus(res.status);
    } catch (error) {
      setStatus(res.status);
    }
  }, [location]);

  return (
    <div className="bg-white">
      <NavigationBar />
      {status != 200 ? (
        <div className="flex flex-col my-48 mx-80 h-[50vh] font-serif2 text-xl">
          <div className="font-serif text-5xl my-10">Félicitation !</div>
          <div className="my-5">
            Vous avez confirmé votre email avec succès. Vous pouvez à présent
            accéder à toutes les fonctionnalités du site.
          </div>
          <div>
            Rendez-vous sur la page{" "}
            <span
              className="text-purple cursor-pointer"
              onClick={() => {
                navigate("/market");
              }}
            >
              {" "}
              marchés{" "}
            </span>{" "}
            pour commencer à acheter des artctions.
          </div>
        </div>
      ) : (
        <div>Fail</div>
      )}
      <Footer />
    </div>
  );
}
