import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations/translations";

i18n.use(initReactI18next).init({
  resources: translations,
  ns: ["common"],
  defaultNS: "common",
  lng: localStorage.getItem("lng") || "fr",
  reloadOnLanguageChange: true,

  interpolation: {},
});

// i18n.on("languageChanged", (lng) => {
//   localStorage.setItem("lng", lng);
//   if (localStorage.getItem("logged") == "1")
//     updateSettings({
//       language: lng,
//     }).catch(() => message.error(i18n.t("error")));
// });

export default i18n;
