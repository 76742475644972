import React, { useEffect, useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import axios from "../api/axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import ArtctionOwnedComp from "./ArtctionOwnedComp";
import { useNavigate } from "react-router-dom";

export default function ArtctionOwnedMobile() {
  const [owned, setOwned] = useState([]);
  const [query, setQuery] = useState("");
  const [seeMore, setSeeMore] = useState(false);

  const { t } = useTranslation("wallet");

  const navigate = useNavigate();

  function getArtctionOwned() {
    axios
      .get("/work/owned?lang=fr", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + Cookies.get("accessToken"),
        },
      })
      .then((response) => {
        setOwned(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getArtctionOwned();
  }, []);

  return (
    <div className="">
      <p className="xl:text-purple/60 text-white font-serif2 lg:text-lg text-base">
        {t("arctionOwnedTitle")}
      </p>
      <div>
        <div className="flex items-center border border-light-purple bg-white rounded-md w-fit">
          <BiSearch color="#d6d6d6" size={25} className="pl-2" />
          <input
            placeholder={t("searchBar")}
            className="p-2 placeholder-[#d6d6d6] rounded-md"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {owned.length === 0 ? (
          <div className="flex flex-col justify-center items-center my-2 h-96 bg-white border rounded-lg border-light-purple">
            <p className="font-serif md:text-4xl text-3xl font-medium text-purple/80 text-center">
              Vous n'avez pas d'artctions acquises
            </p>
            <p className="font-serif2 text-purple/60 text-center">
              Vous pouvez placer des ordres dans la page
              <span
                className="text-purple cursor-pointer"
                onClick={() => {
                  navigate("/market");
                }}
              >
                {" "}
                marchés{" "}
              </span>
            </p>
          </div>
        ) : seeMore ? (
          owned
            .filter((item) => {
              if (query === "") {
                return owned;
              } else if (
                item.title.toLowerCase().includes(query.toLowerCase())
              ) {
                return owned;
              }
            })
            .map((item, index) => <ArtctionOwnedComp key={index} data={item} />)
        ) : (
          owned
            .filter((item) => {
              if (query === "") {
                return owned;
              } else if (
                item.title.toLowerCase().includes(query.toLowerCase())
              ) {
                return owned;
              }
            })
            .slice(0, 10)
            .map((item, index) => <ArtctionOwnedComp key={index} data={item} />)
        )}
      </div>
      <div className="flex justify-center my-2 text-white font-serif2">
        <button
          className="flex items-center "
          onClick={() => setSeeMore(!seeMore)}
        >
          {owned.length === 0 ? (
            <div></div>
          ) : !seeMore ? (
            <div className="flex items-center">
              {t("seeMore")}
              <BiChevronDown />
            </div>
          ) : (
            <div className="flex items-center">
              {t("seeLess")}
              <BiChevronUp />
            </div>
          )}
        </button>
      </div>
    </div>
  );
}
