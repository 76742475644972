import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import useAuth from "../hooks/useAuth";

export default function MyAccount() {
  const [imported, setImported] = useState(false);
  const [userInfos, setUserInfos] = useState([]);
  const [editProfile, setEditProfile] = useState(true);
  const navigate = useNavigate();

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [cityName, setCityName] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [iban, setIban] = useState("");
  const [cityZIP, setCityZIP] = useState("");

  function handleCheckboxChange() {
    setIsNewsletter(!isNewsletter);
  }

  const { user, updateUserInfos } = useAuth();
  const { t } = useTranslation("myAccount");

  const [isNewsletter, setIsNewsletter] = useState(user.isNewletter);

  function onEditPasswordPressed() {
    navigate("/forgotpassword");
  }

  function handleSubmit(event) {
    console.log("newsletter", isNewsletter);
    event.preventDefault();
    updateUserInfos({
      firstName: firstName,
      lastName: lastName,
      streetNumber: streetNumber,
      streetName: streetName,
      cityName: cityName,
      cityZIP: cityZIP,
      iban: iban,
      phone: phone,
      isNewsletter: isNewsletter,
    });
    setEditProfile(true);
  }

  return (
    <div className="bg-white">
      <NavigationBar />
      <div className="xl:mx-36 h-[70vh] lg:mx-10 mx-5 lg:my-24 my-16 mb-64 bg-white">
        <p className="font-serif2 text-purple">{t("profilInfo")}</p>

        {editProfile ? (
          <div className="border-purple/40 rounded-sm border p-10 mb-10">
            <div className="flex lg:flex-row flex-col lg:space-x-60 space-y-6 lg:space-y-0 lg:text-xl text-lg">
              <div className="flex space-x-10 font-serif2">
                <div className="flex flex-col text-purple space-y-6">
                  <p>{t("titleSurName")}</p>
                  <p>{t("titleAdress")}</p>
                  <p>{t("titleCity")}</p>
                  <p>{t("titlePhone")}</p>
                  <p>{t("titleIBAN")}</p>
                </div>
                <div className="flex flex-col text-even-lighter-gray/70 space-y-6">
                  <p>{user.last_name ? user.last_name : "Pas défini"}</p>
                  <p>{user.street_number + " " + user.street_name}</p>
                  <p>{user.city_name ? user.city_name : "Pas défini"}</p>
                  <p>{user.phone ? user.phone : "Pas défini"}</p>
                  <p>{user.iban ? user.iban : "Pas défini"}</p>
                </div>
              </div>
              <div className="flex space-x-10 font-serif2">
                <div className="flex flex-col text-purple space-y-6">
                  <p>{t("titleFirstName")}</p>
                  <p>{t("titleZipCode")}</p>
                  <p>{t("titleCountry")}</p>
                  <p>{t("titleEmail")}</p>
                </div>
                <div className="flex flex-col text-even-lighter-gray/70 space-y-6">
                  <p>{user.first_name ? user.first_name : "Pas défini"}</p>
                  <p>{user.city_ZIP ? user.city_ZIP : "Pas défini"}</p>
                  <p>{user.country ? user.country : "France"}</p>
                  <p>{user.email ? user.email : "Pas défini"}</p>
                </div>
              </div>
            </div>
            <div className="h-px w-full bg-even-lighter-gray/40 mt-10" />
            <div className="flex justify-between 2xl:flex-row flex-col mt-5 space-y-5 2xl:space-y-0">
              {isNewsletter ? (
                <p className="font-serif2 text-even-lighter-gray/70 xl:text-lg text-base flex items-center">
                  Vous êtes inscrit à la newsletter.
                </p>
              ) : (
                <p className="font-serif2 text-red xl:text-lg text-base flex items-center">
                  Vous n'êtes pas inscrit à la newsletter. Modifiez votre profil
                  pour vous inscrire.
                </p>
              )}
              <div className="md:space-x-10 flex md:flex-row flex-col space-y-3 md:space-y-0">
                <button
                  className="btn bg-gray-dark text-white font-serif2 normal-case font-normal  text-base px-5"
                  onClick={() => setEditProfile(false)}
                >
                  {t("editInfo")}
                </button>
                <button
                  className="btn bg-gray-dark text-white font-serif2 normal-case font-normal  text-base px-5"
                  onClick={onEditPasswordPressed}
                >
                  {t("editPassword")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <form
            className="border-purple/40 rounded-sm border p-10 mb-10"
            onSubmit={handleSubmit}
          >
            <div className="flex lg:space-x-72 lg:flex-row flex-col space-y-6 lg:space-y-0 lg:text-xl text-lg">
              <div className="flex space-x-10 font-serif2 ">
                <div className="flex flex-col text-purple space-y-6">
                  <p>{t("titleSurName")}</p>
                  <p>{t("titleAdress")}</p>
                  <p>{t("titleCity")}</p>
                  <p>{t("titlePhone")}</p>
                  <p>{t("titleIBAN")}</p>
                </div>
                <div className="flex flex-col text-even-lighter-gray/70 space-y-6 ">
                  <input
                    placeholder={user.last_name ? user.last_name : "Pas défini"}
                    onChange={(e) => setLastName(e.target.value)}
                    className="border-purple/40 rounded-sm border-b "
                  />
                  <input
                    placeholder={user.street_number + " " + user.street_name}
                    onChange={(e) => {
                      setStreetNumber(e.target.value.split(" ")[0]);
                      setStreetName(
                        e.target.value.split(" ").slice(1).join(" ")
                      );
                    }} //map the street number and street name
                    className="border-purple/40 rounded-sm border-b "
                  />
                  <input
                    placeholder={user.city_name ? user.city_name : "Pas défini"}
                    onChange={(e) => setCityName(e.target.value)}
                    className="border-purple/40 rounded-sm border-b "
                  />
                  <input
                    placeholder={user.phone ? user.phone : "Pas défini"}
                    onChange={(e) => setPhone(e.target.value)}
                    className="border-purple/40 rounded-sm border-b "
                  />
                  <input
                    placeholder={user.iban ? user.iban : "Pas défini"}
                    onChange={(e) => setIban(e.target.value)}
                    className="border-purple/40 rounded-sm border-b "
                  />
                </div>
              </div>
              <div className="flex space-x-10 font-serif2 ">
                <div className="flex flex-col text-purple space-y-6">
                  <p>{t("titleFirstName")}</p>
                  <p>{t("titleZipCode")}</p>
                  <p>{t("titleCountry")}</p>
                  <p>{t("titleEmail")}</p>
                </div>
                <div className="flex flex-col text-even-lighter-gray/70 space-y-6">
                  <input
                    placeholder={
                      user.first_name ? user.first_name : "Pas défini"
                    }
                    onChange={(e) => setFirstName(e.target.value)}
                    className="border-purple/40 rounded-sm border-b "
                  />
                  <input
                    placeholder={user.city_ZIP ? user.city_ZIP : "Pas défini"}
                    onChange={(e) => setCityZIP(e.target.value)}
                    className="border-purple/40 rounded-sm border-b "
                  />
                  <input
                    placeholder={user.country ? user.country : "France"}
                    onChange={(e) => setCountry(e.target.value)}
                    className="border-purple/40 rounded-sm border-b "
                  />
                  <p>{user.email ? user.email : "Pas défini"}</p>
                </div>
              </div>
            </div>
            <div className="h-px w-full bg-even-lighter-gray/40 mt-10" />
            <div className="flex justify-between lg:flex-row flex-col mt-5 space-y-5 lg:space-y-0">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={user.isNewsletter || isNewsletter}
                  name="isNewsletter"
                  id="isNewsletter"
                  className="font-serif2 text-even-lighter-gray/70"
                  onChange={handleCheckboxChange}
                />
                <label
                  htmlFor="isNewsletter"
                  className=" text-even-lighter-gray font-serif2 text-lg"
                >
                  {t("checkNewsletter")}
                </label>
              </div>
              <div className="space-x-10">
                <button
                  className="btn bg-red hover:bg-dark-red hover:border-none border-none text-white font-serif2 normal-case font-normal text-lg px-10"
                  onClick={() => setEditProfile(true)}
                >
                  {t("cancel")}
                </button>
                <button
                  className="btn bg-green hover:bg-dark-green hover:border-none border-none text-white font-serif2 normal-case font-normal text-lg px-10"
                  type="submit"
                >
                  {t("saveInfo")}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      <Footer />
    </div>
  );
}
