import React from "react";

export default function AdminOrderList({ data }) {
  return (
    <div>
      <div className="border-purple/40 rounded border font-serif2 p-5 px-8 space-y-5">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-xs text-even-lighter-gray">Nom</p>
            <p className="text-lg text-purple">{data.lastName}</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Ordre</p>
            <p className="text-lg text-purple">{data.order}</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Montant total</p>
            <p className="text-lg text-purple">{data.totalPrice} €</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Nombre d'Artctions</p>
            <p className="text-lg text-purple">{data.nb_artction}</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Prix de l'Artction</p>
            <p className="text-lg text-purple">{data.artction_price} €</p>
          </div>
          <div>
            <p className="text-xs text-even-lighter-gray">Date d'expiration</p>
            <p className="text-lg text-purple">{data.expire_date}</p>
          </div>
          <div>
            <button className="bg-black py-2 px-4 mr-10 text-white font-serif2 rounded hover:bg-black/90">
              <p>Plus d'informations</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
