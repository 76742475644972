import React from "react";
import { useTranslation } from "react-i18next";
import Balance from "../components/balance";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";

import Shape1 from "../assets/shapes/Wallet1.png";
import Shape2 from "../assets/shapes/Wallet2.png";
import Shape3 from "../assets/shapes/Wallet3.png";
import Shape4 from "../assets/shapes/Wallet4.png";
import Shape5 from "../assets/shapes/Wallet5.png";
import OrderHistoryMobile from "../components/OrderHistoryMobile";
import ArtctionOwnedMobile from "../components/ArtctionOwnedMobile";
import TransactionHistoryMobile from "../components/TransactionHistoryMobile";

export default function Wallet() {
  const { t } = useTranslation("wallet");

  return (
    <div className="bg-white -z-10">
      <NavigationBar />
      <div className="lg:space-y-56 space-y-40">
        <div className="flex justify-between lg:flex-row flex-col-reverse lg:mx-36 mx-10 lg:mt-20 mt-10 z-10 gap-8 lg:gap-0">
          <div className="lg:w-[32vw] xl:w-[45vw] 2xl:w-[60vw]">
            <TransactionHistoryMobile />
          </div>
          <div>
            <Balance />
          </div>
        </div>
        <div className="bg-black lg:pb-16 pb-8 ">
          <div className="lg:mx-36 mx-10 relative -top-32 flex flex-col xl:flex-row justify-between">
            <div className="2xl:w-[40vw] xl:w-[35vw]">
              <OrderHistoryMobile />
            </div>
            <div className="2xl:w-[40vw] xl:w-[35vw]">
              <ArtctionOwnedMobile />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
