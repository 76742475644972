import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function NavigationBarSignin() {
  const { t, i18n } = useTranslation("navbar");

  return (
    <div className="navbar bg-gray-dark font-serif text-white flex justify-between">
      <div className="flex-1 ml-16 xl:ml-36">
        <Link
          to="/"
          className="bg-mobileLogo bg-cover h-5 w-20 xl:bg-fullLogo xl:bg-contain xl:w-96"
        />
      </div>
      <div className="flex mr-16 lg:mr-36 2xl:text-lg xl:text-base lg:text-sm">
        <ul className="flex row space-x-6 items-center">
          <li>
            <Link
              to="/contact"
              className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100
                "
            >
              {" "}
              {t("contact")}{" "}
            </Link>
          </li>
          <li>
            <Link
              to="/help"
              className=" inline-block
                relative
                cursor-pointer
                transition-all
                duration-500
                before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
            >
              {" "}
              {t("help")}{" "}
            </Link>
          </li>
          <li className="dropdown dropdown-hover">
            <label
              tabIndex="0"
              className="text-white pb-4 before:content-['']
                before:absolute
                before:-bottom-[0.5px]
                before:left-0
                before:w-0
                before:h-px
                before:rounded-full
                before:opacity-0
                before:transition-all
                before:duration-300
                before:bg-white
                hover:before:w-full
                hover:before:opacity-100"
            >
              {t("languages")}
            </label>
            <ul
              tabIndex="0"
              className="dropdown-content menu p-2 shadow bg-light-gray w-32 top-10"
            >
              <li className="pb-2">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "fr");
                    i18n.changeLanguage("fr");
                  }}
                >
                  Français
                </a>
              </li>
              <div className="h-px w-auto bg-[#707070]" />
              <li className="pb-2 pt-2">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "en");
                    i18n.changeLanguage("en");
                  }}
                >
                  English
                </a>
              </li>
              <div className="h-px w-auto bg-[#707070]" />
              <li className="pt-2">
                <a
                  onClick={() => {
                    localStorage.setItem("lng", "ch");
                    i18n.changeLanguage("ch");
                  }}
                >
                  中国人
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

// {
//   /* <div className="fixed top-0 left-0 w-screen h-14 bg-gray-dark"></div>; */
// }
