import { useState } from "react";
import { createContext } from "react";
import Cookies from "js-cookie";
import axios from "../api/axios";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [isAuthentificated, setIsAuthentificated] = useState(null);
  const [user, setUser] = useState({});

  async function getUserInfo() {
    const res = await axios.get("/user", {
      headers: {
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
    });
    try {
      setUser(() => res.data);
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  }

  function logout() {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    setIsAuthentificated(() => false);
  }

  async function updateUserInfos({
    firstName,
    lastName,
    streetNumber,
    streetName,
    cityName,
    cityZIP,
    iban,
    phone,
    isNewsletter,
  }) {
    const res = axios.post(`/user`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("accessToken")}`,
      },
      params: {
        first_name: firstName || user.first_name,
        last_name: lastName || user.last_name,
        street_number: streetNumber || user.street_number,
        street_name: streetName || user.street_name,
        city_ZIP: cityZIP || user.city_ZIP,
        city_name: cityName || user.city_name,
        phone: phone || user.phone,
        iban: iban || user.iban,
        isNewsletter: isNewsletter || user.isNewsletter,
      },
    });
    try {
      getUserInfo();
      console.log("updated successfully");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        isAuthentificated,
        setIsAuthentificated,
        logout,
        setUser,
        user,
        updateUserInfos,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
