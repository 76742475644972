import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import NavigationBar from "../components/navigationbar";
import CarouselHome from "../components/CarouselHome";
import { useNavigate } from "react-router-dom";
import heroImage from "../assets/Pairedevasesdelafamilleroseenporcelaine-0.jpg";
import axios from "../api/axios";

const LANG = localStorage.getItem("lng") || "fr";
const PAGE = 0;

export default function Home() {
  const navigate = useNavigate();

  function onCataloguePressed() {
    navigate("/market");
  }
  const [featured, setFeatured] = useState([]);

  function getFeatured() {
    axios
      .get(`/work/featured?lang=${LANG}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setFeatured(res.data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    getFeatured();
  }, []);

  return (
    <div className="h-screen bg-white">
      <NavigationBar />
      <div className="flex lg:flex-row flex-col items-center xl:mx-72 lg:xm-42 mx-10 mt-40">
        <div className="flex flex-col lg:flex-row w-full lg:items-left items-center justify-between">
          <div className="lg:space-y-40 space-y-12 flex flex-col lg:items-start items-center">
            <div className="font-serif2 space-y-5 ">
              <h1 className=" font-bold text-6xl lg:w-[70%] text-black text-center lg:text-left">
                Lorem ipsum dolor sit amet
              </h1>
              <p className=" text-light-gray text-lg lg:w-[80%] lg:text-left text-center">
                Lorem ipsum dolor sit amet, lorem ipsum dolor sit amet lorem
                ipsum dolor sit amet
              </p>
            </div>
            <button
              className="btn bg-gray-dark w-[200px]  text-white font-serif2 normal-case font-normal text-lg px-10"
              onClick={onCataloguePressed}
            >
              Portefeuille
            </button>
          </div>
          <div className="flex gap-5 space-y-10 mt-32 lg:mt-0">
            <div className="flex flex-col gap-5">
              <img src={heroImage} alt="" className="w-48 rounded-xl" />
              <img src={heroImage} alt="" className="w-48 rounded-xl" />
            </div>
            <div className="flex flex-col gap-5">
              <img src={heroImage} alt="" className="w-48 rounded-xl" />
              <img src={heroImage} alt="" className="w-48 rounded-xl" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black -z-10 w-full lg:h-[98rem] h-[143rem] mt-48 lg:mt-0 absolute"></div>
      <div className="flex flex-col gap-40 mt-32">
        <div className="flex flex-col lg:flex-row items-center gap-20 mx-36">
          <div className="bg-dark-black w-80 h-96 rounded-xl"></div>
          <div className="flex flex-col font-serif2 gap-2 lg:text-left text-center">
            <p className="text-white text-4xl font-bold w-80">
              Lorem ipsum dolor sit amet
            </p>
            <p className="text-light-purple">
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem{" "}
              <br />
              ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row justify-end gap-20 items-center">
          <div className="flex flex-col font-serif2 gap-2 lg:text-right w-80 text-center">
            <p className="text-white text-4xl font-bold">
              Lorem ipsum dolor sit amet
            </p>
            <p className="text-light-purple">
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem{" "}
              <br />
              ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </p>
          </div>
          <div className="bg-dark-black lg:w-1/3 w-80 h-96 rounded-xl lg:rounded-tr-none lg:rounded-br-none"></div>
        </div>
        <div className="flex items-center gap-20 lg:gap-36 mx-36 flex-col lg:flex-row">
          <div className="bg-[#0f0f0f] lg:w-1/3 w-80 h-96 rounded-xl">
            <div className="bg-dark-black relative lg:w-10/12 w-80 lg:h-80 h-96 rounded-xl lg:-right-32 lg:-bottom-20"></div>
          </div>

          <div className="flex flex-col font-serif2 gap-2 lg:text-left w-80 text-center">
            <p className="text-white text-4xl font-bold">
              Lorem ipsum dolor sit amet
            </p>
            <p className="text-light-purple">
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem{" "}
              <br />
              ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mb-40 mt-72 lg:mt-40 bg-white">
        <p className="text-5xl font-serif2 font-bold text-dark-black">
          Populaires
        </p>
        <div className="flex gap-10 ">
          <CarouselHome data={featured} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
