import React from "react";

export default function AdminNotValidatedUsersList({ data }) {
  return (
    <div className="border-purple/40 rounded border font-serif2 p-5 px-8 space-y-5">
      <div className="flex justify-between ">
        <div>
          <p className="text-xs text-even-lighter-gray">Nom</p>
          <p className="text-lg text-purple">{data.lastName}</p>
        </div>
        <div>
          <p className="text-xs text-even-lighter-gray">Prénom</p>
          <p className="text-lg text-purple">{data.firstName}</p>
        </div>
        <div>
          <p className="text-xs text-even-lighter-gray">Adresse postale</p>
          <p className="text-lg text-purple">{data.address}</p>
        </div>
        <div>
          <p className="text-xs text-even-lighter-gray">Pays</p>
          <p className="text-lg text-purple">{data.country}</p>
        </div>
        <div>
          <p className="text-xs text-even-lighter-gray">Adresse email</p>
          <p className="text-lg text-purple">{data.email}</p>
        </div>
        <div>
          <p className="text-xs text-even-lighter-gray">Numéro de téléphone</p>
          <p className="text-lg text-purple">{data.phone}</p>
        </div>
        <div>
          <p className="text-xs text-even-lighter-gray">RIB</p>
          <p className="text-lg text-purple">Validé</p>
        </div>
        <div>
          <p className="text-xs text-even-lighter-gray">Papiers d'identité</p>
          <button className="text-lg text-purple hover:underline">
            Consulter le document
          </button>
        </div>
      </div>
      <div>
        <button className="bg-black py-2 px-4 text-white rounded hover:bg-black/90">
          <p>Valider le compte</p>
        </button>
      </div>
    </div>
  );
}
