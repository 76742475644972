import TextField from "@mui/material/TextField";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import NavBarMenuSignin from "../components/navigationbar-signin";

import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "../api/axios";
import { BsDownload } from "react-icons/bs";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [surName, setSurName] = useState("");
  const [phone, setPhone] = useState("");
  const [adress, setAdress] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isNewsletter, setIsNewsletter] = useState(false);

  const [file, setFile] = useState(null);

  const { t } = useTranslation("signup");

  const navigate = useNavigate();

  function handleCheckboxChange() {
    setIsNewsletter(!isNewsletter);
  }

  function onForgotPasswordPressed() {
    navigate("/forgotpassword");
  }

  const refCaptcha = useRef(null);

  function handleFileChange(e) {
    setFile(e.target.files[0]);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(file);
    let formData = new FormData();
    formData.append("id", file, JSON.stringify(file.name));

    try {
      const response = await axios({
        method: "post",
        url: "/auth/register",
        data: formData,
        params: {
          first_name: firstName,
          last_name: surName,
          street_number: streetNumber,
          street_name: streetName,
          city_ZIP: postalCode,
          city_name: city,
          country: country,
          email: email,
          phone: phone,
          password: password,
          is_newsletter: isNewsletter,
        },
        // headers: {
        //   "Content-Type": "multipart/form-data",
        // },
      });
      console.log("register", response);
    } catch (error) {
      if (error?.statusCode === 403) {
        console.log("email in use");
      } else if (error?.statusCode === 400) {
        console.log(error?.message);
      }
    }
  }

  return (
    <div className="bg-white h-screen flex flex-col justify-between">
      <NavBarMenuSignin />
      <div className="xl:ml-80 lg:mx-0 flex flex-col mx-8 lg:ml-56 my-20 w-fit">
        <div>
          <p className="font-serif text-black text-5xl"> {t("register")}</p>
          <p className="font-serif2 text-even-lighter-gray text-xl mb-5">
            {t("registerMessage")}
          </p>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="flex flex-col justify-between h-96 w-fit">
            <div className="flex flex-row justify-between">
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                label={t("surName")}
                style={{ width: "205px" }}
                size="small"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                label={t("firstName")}
                style={{ width: "205px" }}
                size="small"
                value={surName}
                onChange={(e) => setSurName(e.target.value)}
              />
            </div>
            <div className="flex flex-row justify-between">
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                label={t("adress")}
                style={{ width: "260px" }}
                size="small"
                value={adress}
                onChange={(e) => {
                  setStreetNumber(e.target.value.split(" ")[0]);
                  setStreetName(e.target.value.split(" ").slice(1).join(" "));
                  setAdress(e.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="text"
                label={t("zipCode")}
                style={{ width: "150px" }}
                size="small"
                value={postalCode}
                pattern="[0-9]*"
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </div>
            <span>
              <TextField
                id="outlined-basic"
                label={t("city")}
                type="text"
                variant="outlined"
                style={{ width: "425px" }}
                size="small"
                onChange={(e) => setCity(e.target.value)}
              />
            </span>
            <span>
              <TextField
                id="outlined-basic"
                label={t("country")}
                type="text"
                variant="outlined"
                style={{ width: "425px" }}
                size="small"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </span>
            <span>
              <TextField
                id="outlined-basic"
                type="email"
                label={t("email")}
                variant="outlined"
                autoComplete="current-email"
                style={{ width: "425px" }}
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </span>
            <span>
              <TextField
                id="outlined-basic"
                type="tel"
                label={t("phone")}
                variant="outlined"
                style={{ width: "425px" }}
                size="small"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </span>
            <span>
              <TextField
                id="outlined-basic"
                label={t("password")}
                type="password"
                variant="outlined"
                style={{ width: "425px" }}
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </span>
            <span>
              <TextField
                id="outlined-basic"
                label={t("confirmPassword")}
                type="password"
                variant="outlined"
                style={{ width: "425px" }}
                size="small"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                error={password !== confirmPassword}
                helperText={
                  password !== confirmPassword
                    ? "Pas de correspondance entre les mots de passes."
                    : ""
                }
              />
            </span>
          </div>
          <div className="flex flex-col my-2 font-serif2 text-even-lighter-gray">
            <FormControlLabel control={<Checkbox />} label={t("acceptTerms")} />
            <FormControlLabel
              control={<Checkbox />}
              label={t("newsletter")}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="flex flex-col">
            <input
              type="file"
              onChange={(e) => handleFileChange(e)}
              id="getID"
              className="overflow-hidden absolute w-[0.1px] h-[0.1px] opacity-0 -z-[1]"
              accept=".png, .jpg, .jpeg, .pdf, image/jpeg, image/png, application/pdf"
            />
            <label
              htmlFor="getID"
              className="btn w-72 h-10 gap-2 text-black bg-transparent hover:text-white hover:bg-gray-dark normal-case font-serif2 font-normal"
            >
              <BsDownload size={18} />
              {t("importID")}
            </label>
            <p className="font-serif2 text-even-lighter-gray">
              {t("IDMessage")}
            </p>
          </div>
          <div className="mb-6 mt-5 rounded px-7 py-5 border border-even-lighter-gray w-72 flex flex-row justify-start space-x-3 text-black">
            <input type="checkbox" />
            <p>I'm not a robot</p>
          </div>
          <div className="mt-7 flex items-center  flex-col lg:flex-row w-full">
            <button
              className="btn bg-gray-dark text-white font-serif2 normal-case font-normal lg:w-fit w-full text-lg px-10"
              type="submit"
            >
              {t("registerBtn")}
            </button>
            <p className="font-serif2 text-even-lighter-gray lg:ml-5 ">
              {t("alreadyRegistered")}{" "}
              <a href="/signin" className="text-purple">
                {t("goToLogin")}
              </a>
            </p>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
}
