import React, { useEffect, useRef } from "react";
import Footer from "../components/footer";
import NavigationBarSignin from "../components/navigationbar-signin";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import useAuth from "../../src/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import Cookies from "js-cookie";

export default function SignIn2() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wrongCred, setWrongCred] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const emailRef = useRef();
  const refCaptcha = useRef(null);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const { t } = useTranslation("signin");
  const { setAuth } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "/auth/login",
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log(response?.data?.bearerToken);
      const accessToken = response?.data?.bearerToken;
      const refreshToken = response?.data?.refreshToken;
      const isAdmin = response?.data?.is_admin;
      console.log("admin", isAdmin);
      Cookies.set("accessToken", accessToken);
      Cookies.set("refreshToken", refreshToken);
      setAuth({
        email,
        password,
        accessToken,
        refreshToken,
        isAdmin,
      });
      setWrongCred(false);
      setEmail("");
      setPassword("");
      if (isAdmin) {
        navigate("/adminhome", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } catch (error) {
      if (error.response.status === 401) {
        console.log("Wrong Credientials");
        setWrongCred(true);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <div className="bg-white h-screen flex flex-col justify-between">
      <NavigationBarSignin />
      <div className="lg:ml-80 flex items-center flex-col lg:items-start mb-20 space-y-24">
        <div>
          <p className="font-serif text-black text-5xl">{t("login")}</p>
          <p className="font-serif2 text-even-lighter-gray text-xl mb-5">
            {t("loginText")}
          </p>
        </div>
        <form
          className="flex flex-col w-fit h-48 justify-evenly"
          onSubmit={handleSubmit}
        >
          <div className="space-y-4">
            <TextField
              id="outlined-basic"
              type="email"
              ref={emailRef}
              label={t("emailPlaceholder")}
              variant="outlined"
              autoComplete="current-email"
              style={{ width: "425px" }}
              value={email}
              error={wrongCred}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <div className="flex flex-col items-end space-y-px">
              <TextField
                id="outlined-basic"
                label={t("passwordPlaceholder")}
                type="password"
                autoComplete="current-password"
                variant="outlined"
                style={{ width: "425px" }}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                required
                error={wrongCred}
                helperText={wrongCred ? "Identifiants incorrects" : ""}
              />
              <a href="/forgotpassword" className="text-purple">
                {t("forgotPasswordLink")}
              </a>
            </div>
          </div>
          <div className="mb-6 mt-5 rounded px-7 py-5 border border-even-lighter-gray w-72 flex flex-row justify-start space-x-3 text-black">
            <input type="checkbox" />
            <p>I'm not a robot</p>
          </div>
          <div className="flex lg:items-center flex-col text-right mt-10 lg:flex-row">
            <button
              className="btn bg-gray-dark text-white font-serif2 normal-case font-normal text-lg px-10"
              type="submit"
            >
              {t("loginBtn")}
            </button>
            <p className="font-serif2 text-even-lighter-gray lg:ml-5">
              {t("signUpText")}{" "}
              <a href="/signup" className="text-purple">
                {t("signUpLink")}
              </a>
            </p>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
